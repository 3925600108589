<template>
  <div class="background">
    <div
      v-for="value in options"
      :key="value.id"
      :style="setItemStyle()"
      :class="{
        item: true,
        active: value.value === state,
        'small-padding': smallPadding,
      }"
      @click="change(value.value)"
    >{{ value?.text }}</div
    >
  </div>
</template>

<script>
export default {
  props: {
    state: {
      type: [Number, String],
      default: 2,
    },
    values: {
      type: Array,
      default: () => [],
    },
    url: {
      type: String,
      default: '',
    },
    smallPadding: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:state'],
  data() {
    return {
      options: this.values,
    };
  },
  async mounted() {
    if (this.url !== '') {
      const list = await this.$store.dispatch(this.url, {});
      this.options = list?.data;
    }
  },
  methods: {
    change(value) {
      this.$emit('update:state', value);
    },
    setItemStyle() {
      return this.smallPadding ? 'width: auto' : `width: calc(100% / ${this.options.length});`;
    },
  },
};
</script>

<style scoped>
.background {
  background: #f5f5f5;
  border-radius: 32px;
  display: inline-flex;
  justify-content: space-between;
  height: fit-content;
  width: 100%;
}

.item {
  padding-top: 11px;
  padding-bottom: 11px;
  font-weight: 500;
  font-size: 16px;
  line-height: 135%;
  text-align: center;
  vertical-align: middle;
  border-radius: 32px;
  cursor: pointer;
}
.item.small-padding {
  padding: 11px 20px;
}

.item:hover {
  background: #404040;
  color: #ffffff;
}
.active {
  background: #000000;
  color: #ffffff;
}
</style>
