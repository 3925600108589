import { ethers } from 'ethers';
import contractAbi from '@/assets/web3/auction.json';
import axiosInstance2 from '@/plugins/serviceA';
import router from '@/router';

const actions = {
  async createAuction({ rootGetters }, auctionData) {
    try {
      const nftTokenId = await actions.getNftToken(auctionData.nft_id, this.$jwt.getToken());
      let signHash = null;
      if (auctionData.sale_type_id === 1) {
        signHash = await actions.createFixPriceAuction(rootGetters, nftTokenId.token_id, auctionData.price);
      } else if (auctionData.sale_type_id === 2) {
        signHash = await actions.createReserveAuction(
          rootGetters,
          nftTokenId.token_id,
          auctionData.price,
        );
      }

      const auctionId = await actions.saveReserveAuction(auctionData, signHash, this.$jwt.getToken());
      console.log(`Аукцион успешно создан. Идентификатор: ${auctionId.sale_id}`)

      router.push({ name: 'Auction', params: { id: auctionData.nft_id }});
    } catch (e) {
      console.error(e);
    }
  },

  /**
   * Получение токена сминтченной nft
   *
   * @param mintedNftId
   * @param token
   * @returns {Promise<any>}
   */
  async getNftToken(mintedNftId, token) {
    const { data } = await axiosInstance2.get(
      `v1/nft/${mintedNftId}/token-id`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return data;
  },

  /**
   * Создание аукциона со ставками через контракт
   *
   * @param rootGetters
   * @param tokenId
   * @param reservePrise
   * @returns {Promise<*>}
   */
  async createReserveAuction(rootGetters, tokenId, price) {
    const web3 = await rootGetters['wallet/getWeb3Modal'].connect();
    const provider = new ethers.providers.Web3Provider(web3);
    const signer = provider.getSigner();
    const address = process.env.VUE_APP_SALE_NFT_ADDRESS;
    const addressNft = process.env.VUE_APP_MINTING_CONTRACT_ADDRESS;
    let contr = new ethers.Contract(address, contractAbi, provider);
    console.log(addressNft, tokenId, price);
    const result = await contr
      .connect(signer)
      .createReserveAuction(addressNft, tokenId, parseFloat(price * 1000000000000000000).toString());

    return result.hash;
  },

  /**
   * Создание аукциона с единственной ценой через контракт
   *
   * @param rootGetters
   * @param tokenId
   * @param reservePrise
   * @returns {Promise<*>}
   */
  async createFixPriceAuction(rootGetters, tokenId, price) {
    const web3 = await rootGetters['wallet/getWeb3Modal'].connect();
    const provider = new ethers.providers.Web3Provider(web3);
    const signer = provider.getSigner();
    const address = process.env.VUE_APP_SALE_NFT_ADDRESS;
    const addressNft = process.env.VUE_APP_MINTING_CONTRACT_ADDRESS;
    let contr = new ethers.Contract(address, contractAbi, provider);
    const result = await contr
      .connect(signer)
      .listItem(addressNft, tokenId, parseFloat(price * 1000000000000000000).toString());

    return result.hash;
  },

  /**
   * Сохранение аукциона в системе
   *
   * @param auctionData
   * @param hash
   * @param jwt
   * @returns {Promise<any>}
   */
  async saveReserveAuction(auctionData, hash, jwt) {
    const { data } = await axiosInstance2.post(
      'v1/nft/sale',
      {
        nft_id: parseInt(auctionData.nft_id),
        start_at: auctionData.start_at,
        duration: auctionData.duration,
        price: {
          value: parseInt(auctionData.price * 1000000000000000000),
        },
        transaction_hash: hash,
        reservation: {
          is_reserved: auctionData.specBayer?.switchValue,
          wallet: auctionData.specBayer?.textValue,
        },
        sale_type_id: auctionData.sale_type_id,
        method_id: auctionData.method,
      },
      {
        headers: { Authorization: `Bearer ${jwt}` },
      },
    );
    return data;
  },
};

export default {
  namespaced: true,
  actions,
};
