<template>
  <a :href="`/${data?.username}/created`"
     class="author">
    <div class="avatar"
         :style="avatarSrc"></div>
    <div class="author-name"> by {{ data?.username || 'some Nick' }}</div>
  </a>
</template>

<script>
export default {
  name: 'NftAuthor',

  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    avatarSrc() {
      return 'background-image:' + `url(${this.data?.avatar})`;
    },
  },
};
</script>

<style>
.author {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 11px;
  padding: 2px 10px 2px 2px;
  height: 26px;
  background: #ffffff;
  border-radius: 50px;
}
.avatar {
  width: 21px;
  height: 21px;
  margin-right: 5px;
  border-radius: 50%;
  background-position: center;
  background-color: #6f7127;
  background-size: cover;

}
.author-name {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
}
</style>
