<template>
  <div :id="`simple-map${containerSalt}`"
       :class="['map', minHeight ? 'min-height' : '']"></div>
</template>

<script>
import mapboxgl from 'mapbox-gl';
import markerIco from '@/assets/img/icons/multipe-map-item.svg';
import icon from '@/assets/img/icons/avatar-preview.svg';

export default {
  name: 'MultipleItemsMap',

  props: {
    items: {
      type: Array,
      required: true,
    },
    minHeight: {
      type: Boolean,
      default: false,
    },
    centerCoordinates: {
      type: Array,
      required: false,
      default: () => [30.326575, 59.940849],
    },
  },
  emits: ['locationInputChange'],
  data() {
    return {
      location: this.centerCoordinates,
      map: null,
      containerSalt: Math.random(),
      pinataAdress: process.env.VUE_APP_PINATA_ADRESS,
      icon: icon,
    };
  },
  watch: {
    items: {
      handler() {
        this.initMapbox();
      },
      deep: true,
    },
  },
  async mounted() {
    await this.initMapbox();
    const resizer = new ResizeObserver(() => this.map.resize(), 10);
    resizer.observe(document.getElementById(`simple-map${this.containerSalt}`));
  },
  methods: {
    // eslint-disable-next-line max-lines-per-function
    initMapbox() {
      mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_TOKEN;
      this.map = new mapboxgl.Map({
        container: `simple-map${this.containerSalt}`,
        style: 'mapbox://styles/mapbox/outdoors-v11',
        center: this.location,
        zoom: 10,
        scrollZoom: true,
      });
      this.map.addControl(new mapboxgl.NavigationControl({ showCompass: false }), 'top-right');

      this.items.forEach(
          // eslint-disable-next-line max-lines-per-function
        function (item) {
          if (item?.nft?.location?.lat && item?.nft?.location?.lng) {
            const el = document.createElement('div');
            el.style =
              `background-image: url(${markerIco});\n` +
              '  background-repeat: no-repeat;\n' +
              '  background-position: center;\n' +
              '  width: 45px;\n' +
              '  height: 45px;\n' +
              '  cursor: pointer;';
            const path = item.nft.file_path.replace('ipfs://', '');
            const html =
                '<div class="mapboxgl-popup-content-wrapper">' +
                '  <div class="mapboxgl-popup-image-wrapper">' +
                '   <img src="' + this.pinataAdress + path + '" class="mapboxgl-popup-image" alt="nft image"/>' +
                '  </div>' +
                '  <div class="mapboxgl-popup-info">' +
                '    <h3 class="mapboxgl-popup-info-title">' + item?.nft?.name + '</h3>' +
                '    <div class="mapboxgl-popup-info-links">' +
                '      <div class="mapboxgl-popup-info-links-city">' + (item?.nft?.location.name || 'Somewhere') + '</div>' +
                '      <a href="/' + item.nft.creator.username + '/created" class="mapboxgl-popup-info-links-creator" target="_blank">' +
                '        <div class="mapboxgl-popup-info-links-creator-avatar" style="background-image: url(' + (item?.nft?.avatar || this.icon) + ')"></div>' +
                '        <div class="mapboxgl-popup-info-links-creator-name">by ' + (item.nft.creator.username || 'noNick') + '</div>' +
                '      </a>' +
                '    </div>' +
                '  </div>' +
                '</div>';
            const popupOffsets = {
              'top': [90, -25],
              'top-left': [-32, -35],
              'top-right': [207, -28],
              'bottom': [90, 310],
              'bottom-left': [-32, 310],
              'bottom-right': [207, 310],
              'left': [-32, 137],
              'right': [207, 137],
            };
            // eslint-disable-next-line vue/max-len
            const popup = new mapboxgl.Popup({ offset: popupOffsets, closeButton: true, closeOnClick: true }).setHTML(html);

            const marker = new mapboxgl.Marker(el)
                .setLngLat([item?.nft?.location?.lng, item.nft.location.lat])
                .setPopup(popup)
                .addTo(this.map);

            marker.getElement().addEventListener('click', () => {
              this.map.flyTo({
                center: marker._lngLat,
              });
            });
          }
        }.bind(this),
      );
    },
  },
};
</script>

<style scoped>
.map {
  min-height: 600px;
  width: 100%;
  border-radius: 15px;
}
.min-height {
  min-height: 400px;
}
@media only screen and (max-width: 700px) {
  .min-height {
    height: 500px;
  }
}
</style>
