import axiosInstance2 from '@/plugins/serviceA';

const state = () => ({
  follows: [],
});

const mutations = {
  GET_USER_FOLLOWS: (state, res) => {
    state.follows = res.data;
  },
  GET_USER_FOLLOWERS: (state, res) => {
    state.follows = res.data;
  },
  FOLLOW_USER: (state, user_id) => {
    state.follows.push(user_id);
  },
  UNFOLLOW_USER: (state, id) => {
    state.follows = state.follows.filter(user => user !== id);
  },
};

const actions = {
  async GET_FOLLOWS_OF_USER({ commit }) {
    await axiosInstance2
      .get(
        'v1/user/follows',
        {},
        {
          headers: {
            Authorization: `Bearer ${this.$jwt.getToken()}`,
          },
        },
      )
      .then(res => commit('GET_USER_FOLLOWS', res))
      .catch(error => {
        console.log(error);
      });
  },

  async GET_USERS_FOLLOWERS({ commit }) {
    await axiosInstance2
      .get(
        'v1/user/followers',
        {},
        {
          headers: {
            Authorization: `Bearer ${this.$jwt.getToken()}`,
          },
        },
      )
      .then(res => commit('GET_USER_FOLLOWERS', res))
      .catch(error => {
        console.log(error);
      });
  },

  async FOLLOW_USER({ commit }, user_id) {
    await axiosInstance2
      .post(
        `v1/user/${user_id}/follow`,
        {},
        {
          headers: {
            Authorization: `Bearer ${this.$jwt.getToken()}`,
          },
        },
      )
      .then(res => commit('FOLLOW_USER', res))
      .catch(error => {
        console.log(error);
      });
  },

  async UNFOLLOW_USER({ commit }, id) {
    await axiosInstance2
      .delete(
        `v1/user/${id}/unfollow`,
        {},
        {
          headers: {
            Authorization: `Bearer ${this.$jwt.getToken()}`,
          },
        },
      )
      .then(res => commit('UNFOLLOW_USER', res))
      .catch(error => {
        console.log(error);
      });
  },
};

export default {
  namespaced: true,
  actions,
  state,
  mutations,
};
