import QUERIES from '@/services/graphQueries';
import axiosInstance2 from '@/plugins/serviceA.js';

// temporary vars
// todo: ID from metamsk
const TMP_LOGGED_USER_ID = '0x60fea001';

const state = () => ({
  loggedUserId: null,
  loggedUserSubscriptions: [],
  likedItems: [],
  profile: {},
  activities: [],
  statement: [],
  previewData: {},
  nonce: null,
});

const actions = {
  // eslint-disable-next-line no-empty-pattern
  async getUserByPublicAddress({}, publicAddress) {
    const params = {
      address: publicAddress,
    };
    return await this.$api.user
      .get('address', { params })
      .then(data => data.nonce)
      .catch(error => {
        if (error.response.status === 404) {
          return 'not found';
        } else {
          return null;
        }
      });
  },
  // eslint-disable-next-line no-empty-pattern
  async signUpByPublicAddress({}, publicAddress) {
    return this.$api.auth
      .signUpAddress({ publicAddress: publicAddress })
      .then(data => data.nonce)
      .catch(() => null);
  },
  async loginBySignature({ dispatch }, [publicAddress, signature]) {
    return this.$api.auth
      .signInAddress({ publicAddress: publicAddress, signature: signature })
      .then(data => {
        this.$jwt.saveToken(data.token);
        this.$api.auth.setAuthHeader();

        dispatch('loadProfile');
        return true;
      });
  },
  async login({ dispatch }) {
    this.$api.auth.auth({ userId: TMP_LOGGED_USER_ID }).then(data => {
      this.$jwt.saveToken(data.accessToken);
      this.$api.auth.setAuthHeader();

      dispatch('loadProfile');
    });
  },

  async logout({ commit }, sendRequest = false) {
    if (sendRequest) {
      await this.$api.auth.logout();
    }
    this.$jwt.destroyToken();
    this.$api.auth.setAuthHeader();

    commit('SET_USER_ID', null);
  },

  loadProfile({ commit }) {
    return QUERIES.getProfile(TMP_LOGGED_USER_ID)
      .then(response => {
        const profile = response.data.getUserById;
        commit('SET_PROFILE', profile);
        commit(
          'SET_USER_SUBSCRIPTIONS',
          profile.subscriptions.map(item => item.id),
        );
        commit('SET_USER_ID', profile.id);
      })
      .catch(error =>
        // prettier-ignore
        console.info('%c ERROR in loggedUser->loadProfile 🤬', 'color: #eee; background-color: #ff2020', error),
      );
  },

  loadActivity({ state, commit }) {
    return this.$api.activity
      .get(state.loggedUserId)
      .then(activity => commit('SET_ACTIVITY', activity));
  },

  loadStatement({ state, commit }) {
    return this.$api.statement
      .get(state.loggedUserId)
      .then(statement => commit('SET_STATEMENT', statement));
  },

  addToPreviewData({ commit }, data) {
    commit('SET_PREVIEW_DATA', data);
  },

  /**
   * Получает страницу с авторизацией для твиттера и открывает ее в новом окне
   *
   * @returns {Promise<void>}
   */
  async verifyUserByTwitter() {
    const { data } = await axiosInstance2.get(
      'v1/twitter/login',
      {},
      {
        headers: { Authorization: `Bearer ${this.$jwt.getToken()}` },
      },
    );
    window.location.href = data.redirect_url;
  },
};

const mutations = {
  SET_USER_NONCE(state, nonce) {
    state.nonce = nonce;
  },

  SET_USER_SUBSCRIPTIONS(state, subscriptions) {
    state.loggedUserSubscriptions = subscriptions;
  },

  SET_USER_ID(state, id) {
    state.loggedUserId = id;
  },

  SET_PROFILE(state, profile) {
    state.profile = profile;
  },

  SET_ACTIVITY: (state, activities) => {
    state.activities = activities;
  },

  SET_STATEMENT: (state, statement) => {
    state.statement = statement;
  },

  SET_PREVIEW_DATA: (state, data) => {
    state.previewData = Object.assign(data);
  },
};

const getters = {
  PREVIEW_DATA(state) {
    return state.previewData;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
