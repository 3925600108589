<template>
  <div class="carousel-main">
    <div class="carousel-controls">
      <div :class="['carousel-title', { 'invert-color': invertColors }]">{{ title }}</div>
      <div class="carousel-btns">
        <div class="carousel-arrow carousel-arrow__left"
             @click="offset--"></div>
        <div class="carousel-arrow carousel-arrow__right"
             @click="offset++"></div>
        <!--        todo изменить обязательный атрибут на необязательный -->
        <div class="carousel-view-all"><a :href="`${baseUrl}${viewAllLink}`">VIEW ALL</a></div>
      </div>
    </div>
    <div v-if="currentItems?.length > 0"
         class="carousel-grid">
      <div v-for="(item, index) in currentItems"
           :key="index"
           class="item">
        <ViewCardComponent
          v-if="item?.nft"
          :data="item.nft"
          :big-size-card="bigSizeCard"
          :carousel-card="carouselCard"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ViewCardComponent from '@/components/_UI/viewCardComponent/ViewCardComponent.vue';

export default {
  name: 'CarouselSlider',

  components: { ViewCardComponent },
  props: {
    items: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    invertColors: {
      type: Boolean,
      default: false,
    },
    bigSizeCard: {
      type: Boolean,
      default: false,
    },
    carouselCard: {
      type: Boolean,
      default: false,
    },
    viewAllLink: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentItems: [],
      itemsCount: 4,
      offset: 0,
      baseUrl: process.env.VUE_APP_PNFT_URL,
    };
  },
  watch: {
    items() {
      this.updateGrid();
    },
    offset() {
      this.updateGrid();
    },
    itemsCount() {
      this.updateGrid();
    },
  },
  mounted() {
    this.checkWindowWidth();
    window.addEventListener('resize', this.checkWindowWidth);
  },
  unmounted() {
    window.removeEventListener('resize', this.checkWindowWidth);
  },
  methods: {
    // eslint-disable-next-line max-lines-per-function
    updateGrid() {
      if (!this.items?.length || this.items?.length === 0) {
        return;
      }
      let startIndex = this.offset;
      if (startIndex < 0) {
        if (startIndex * startIndex > this.items?.length * this.items?.length) {
          startIndex %= this.items?.length;
        }
        startIndex = this.items?.length + startIndex;
      } else if (startIndex > this.items?.length) {
        startIndex %= this.items?.length;
      }
      this.currentItems = [];
      while (this.currentItems?.length < this.itemsCount) {
        if (this.items.length && this.items[startIndex]) {
          this.currentItems.push(this.items[startIndex]);
        }
        if (startIndex + 1 < this.items?.length) {
          startIndex++;
        } else {
          startIndex = 0;
        }
      }
    },
    checkWindowWidth() {
      if (window.innerWidth > 1400) {
        this.itemsCount = 4;
      } else if (window.innerWidth > 1100 && window.innerWidth < 1401) {
        this.itemsCount = 3;
      } else if (window.innerWidth > 700 && window.innerWidth < 1101) {
        this.itemsCount = 2;
      } else {
        this.itemsCount = 1;
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.carousel
  &-main
    display: flex
    flex-direction: column
    width: 100%
    justify-content: center

  &-grid
    display: grid
    grid-template-columns: repeat(4, 400px)
    grid-auto-rows: 485px
    justify-content: space-between
    width: 100%
    margin: 0
    gap: 20px

    @media only screen and (max-width: 1700px)
      grid-template-columns: repeat(4, 300px)
      grid-auto-rows: auto

    @media only screen and (max-width: 1400px)
      grid-template-columns: repeat(3, 350px)

    @media only screen and (max-width: 1200px)
      grid-template-columns: repeat(3, 300px)

    @media only screen and (max-width: 1100px)
      grid-template-columns: repeat(2, 400px)
      justify-content: space-evenly

    @media only screen and (max-width: 900px)
      grid-template-columns: repeat(2, 300px)

    @media only screen and (max-width: 800px)
      grid-template-columns: repeat(2, 300px)

    @media only screen and (max-width: 700px)
      grid-template-columns: repeat(1, 400px)

    @media only screen and (max-width: 520px)
      grid-template-columns: repeat(1, 330px)

  &-controls
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: 40px

    @media only screen and (max-width: 520px)
      flex-direction: column
      align-items: flex-start
      margin-bottom: 30px

  &-title
    font-style: normal
    font-weight: 700
    font-size: 30px
    line-height: 40px

    @media only screen and (max-width: 520px)
      margin-bottom: 20px

  &-btns
    display: flex
    gap: 10px
    align-items: center
    min-width: 227px

    @media only screen and (max-width: 520px)
      justify-content: space-between
      width: 100%

  &-arrow
    width: 44px
    height: 44px
    border-radius: 32px
    background: #f5f5f5 url(~@/assets/img/icons/right-arrow.svg) no-repeat center
    cursor: pointer

    &__left
      background-image: url(~@/assets/img/icons/left-arrow.svg)

    &__right
      @media only screen and (max-width: 520px)
        margin-right: auto

  &-view-all
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    border: 2px solid #000000
    border-radius: 32px
    background: #ffffff
    padding: 11px 20px 11px 20px
    font-weight: 500
    font-size: 16px
    line-height: 135%
    text-transform: uppercase
    color: #242424

.invert-color
  filter: invert(1)
</style>
