import {createApp} from 'vue';
import router from './router';
import store from './store';
import App from './App.vue';

// plugins
import VueService from './plugins/vueService';

// styles
import '@/assets/sass/main.sass';

// global components
import BaseButton from '@/components/_UI/BaseButton.vue';
import AppLoader from '@/components/AppLoader.vue';
import AppPagination from '@/components/AppPagination.vue';

const app = createApp(App);

app
  .component('BaseButton', BaseButton)
  .component('AppLoader', AppLoader)
  .component('AppPagination', AppPagination);

app.use(router).use(store).use(VueService).mount('#app');
