import axiosInstance2 from '@/plugins/serviceA';

const actions = {
  async getCategories() {
    try {
      return await axiosInstance2.get(
        'v1/category/all',
        {},
        {
          headers: {
            Authorization: `Bearer ${this.$jwt.getToken()}`,
          },
        },
      );
    } catch (e) {
      // todo Проработать алерты в дизайне
      console.log(e);
    }
  },
  async getMethods() {
    try {
      return await axiosInstance2.get(
        'v1/methods/all',
        {},
        {
          headers: {
            Authorization: `Bearer ${this.$jwt.getToken()}`,
          },
        },
      );
    } catch (e) {
      // todo Проработать алерты в дизайне
      console.log(e);
    }
  },
};
export default {
  namespaced: true,
  actions,
};
