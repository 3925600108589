<template>
  <div :class="['grid', bigSizeCard ? 'big-size' : '']">
    <div v-for="item in items"
         :key="item"
         class="item">
      <ViewCardComponent v-if="item.nft"
                         :data="item.nft"
                         :big-size-card="bigSizeCard" />
      <ViewCollectionComponent
        v-if="item?.collection"
        :collections="item?.collection"
        :small-size-collection="smallSizeCollection"
        :need-button="needButton"
      />
    </div>
  </div>
</template>

<script>
import ViewCardComponent from '@/components/_UI/viewCardComponent/ViewCardComponent.vue';
import ViewCollectionComponent from '@/components/_UI/viewCardComponent/ViewCollectionComponent.vue';

export default {
  name: 'SimpleGrid',

  components: { ViewCollectionComponent, ViewCardComponent },
  props: {
    items: {
      type: Array,
      required: true,
    },
    smallSizeCollection: {
      type: Boolean,
      default: false,
    },
    bigSizeCard: {
      type: Boolean,
      default: false,
    },
    needButton: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped>
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, 260px);
  grid-auto-rows: 280px;
  gap: 20px;
  justify-content: center;
}
.big-size {
  margin-top: 0;
  grid-template-columns: repeat(auto-fill, 400px);
  grid-auto-rows: auto;
}

@media only screen and (max-width: 1320px) {
  .big-size {
    grid-template-columns: repeat(auto-fill, 300px);
  }
}

@media only screen and (max-width: 1070px) {
  .big-size {
    grid-template-columns: repeat(auto-fill, 400px);
  }
}

@media only screen and (max-width: 920px) {
  .big-size {
    grid-template-columns: repeat(auto-fill, 300px);
  }
}

@media only screen and (max-width: 700px) {
  .big-size {
    grid-template-columns: repeat(auto-fill, 400px);
  }
}

@media only screen and (max-width: 530px) {
  .big-size {
    grid-template-columns: repeat(auto-fill, 330px);
  }
}
</style>
