import { checkInLocalStorage, delFromLocaleStorage } from '@/helpers/storageHandler';
import store from '../../store';
import { getToken } from '@/services/jwt.service';
const ID_TOKEN_KEY = process.env.VUE_APP_STORAGE_TOKEN_NAME;

export const auth = ({next}) => {
    if (!checkInLocalStorage(ID_TOKEN_KEY)) {
        next({name: 'MainPage'});
    } else {
        if (deleteIfExpire()) {
            next({name: 'MainPage'});
        } else {
            next();
        }
    }
};

export const checkAuth = async ({ next }) => {
    try {
        if (checkInLocalStorage(ID_TOKEN_KEY)) {
            deleteIfExpire();
        } else {
            if (window.ethereum) {
                if (window.ethereum.isMetaMask) {
                    await connectMetaMask();
                }
            }
        }
    } catch (e) {
        console.log(e);
    } finally {
        next();
    }
};

export const deleteIfExpire = () => {
    let parsedJWT = parseJwt(checkInLocalStorage(ID_TOKEN_KEY));
    let expDate = Math.floor(new Date().getTime() / 1000);
    if (expDate > parsedJWT.exp) {
        delFromLocaleStorage(ID_TOKEN_KEY);
        if (!getToken()) {
            if (window.ethereum) {
                if (window.ethereum.isMetaMask) {
                  connectMetaMask().then(r => console.log(r));
                }
                return true;
            }
        }
    }
    return false;
};

const connectMetaMask = async () => {
    try {
        await store.dispatch('wallet/initWeb3Modal');
        if (!localStorage.getItem('isConnected')) {
            await store.dispatch('wallet/connectWeb3Modal');
        }
        if (localStorage.getItem('isConnected') === 'true') {
            await updateUserdata();
        }
    } catch (e) {
        console.log(e);
    }
}
const updateUserdata = async () => {
    const nonce = await getNonce();
    if (nonce === null) {
        throw new Error();
    } else {
        let signature = await store.dispatch('wallet/signAuthWeb3Modal', nonce);
        let isLogin = await store.dispatch('loggedUser/loginBySignature', [
            window.ethereum.selectedAddress,
            signature,
        ]);
        if (!isLogin) throw new Error();
        if (isLogin) {
            location.reload();
        }
    }
}

const getNonce = async () => {
    let nonce = await store.dispatch(
        'loggedUser/getUserByPublicAddress',
        window.ethereum.selectedAddress,
    );
    if (nonce === 'not found') {
        nonce = await store.dispatch(
            'loggedUser/signUpByPublicAddress',
            window.ethereum.selectedAddress,
        );
    }
    return nonce;
}

export const parseJwt = token => {
  let base64Url = token.split('.')[1];
  let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
  return JSON.parse(jsonPayload);
};
