<template>
  <div :class="['grid', 'grid-collections', smallSizeCollection ? 'small-size' : '']">
    <div v-for="item in items"
         :key="item">
      <ViewCollectionComponent
        v-if="item?.collection"
        :collections="item?.collection"
        :small-size-collection="smallSizeCollection"
        :need-button="needButton"
      />
    </div>
  </div>
</template>

<script>
import ViewCollectionComponent from '@/components/_UI/viewCardComponent/ViewCollectionComponent.vue';

export default {
  components: { ViewCollectionComponent },

  props: {
    items: {
      type: Array,
      required: true,
    },
    smallSizeCollection: {
      type: Boolean,
      default: false,
    },
    needButton: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped>
.grid-collections {
  margin-top: 0;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, 540px);
  gap: 20px;
  justify-content: center;
}
.small-size {
  grid-template-columns: repeat(auto-fill, 400px);
}
@media only screen and (max-width: 1320px) {
  .small-size {
    grid-template-columns: repeat(auto-fill, 300px);
  }
}
@media only screen and (max-width: 1070px) {
  .small-size {
    grid-template-columns: repeat(auto-fill, 400px);
  }
}
@media only screen and (max-width: 920px) {
  .small-size {
    grid-template-columns: repeat(auto-fill, 300px);
  }
}
@media only screen and (max-width: 700px) {
  .small-size {
    grid-template-columns: repeat(auto-fill, 400px);
  }
}
@media only screen and (max-width: 530px) {
  .small-size {
    grid-template-columns: repeat(auto-fill, 330px);
  }
}
</style>
