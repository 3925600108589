import {
  saveToSessionStorage,
  checkInSessionStorage,
  getFromSessionStorage,
} from '@/helpers/storageHandler';

const state = () => ({
  items: checkInSessionStorage('items') ? getFromSessionStorage('items') : [],
  popularItems: [],
  filteredItems: [],
  filters: checkInSessionStorage('filters') ? getFromSessionStorage('filters') : [],
  selectedCategory: 'all-items',
});

const getters = {
  getItemsNumber: state => state.items.length,
};

const actions = {
  loadItemsData({ commit }) {
    return this.$api.items.query().then(data => {
      commit('SET_ITEMS', data);
      commit('SET_FILTERED_ITEMS', data);
      saveToSessionStorage('items', data);
    });
  },

  loadPopularItems({ commit }) {
    return this.$api.items.get('popular').then(data => commit('SET_POPULAR_ITEMS', data));
  },

  filterItemsByCategory({ commit, state }, category) {
    const filteredItems =
      category === 'all-items'
        ? state.items
        : state.items.filter(item => item.category === category);

    commit('SET_FILTERED_ITEMS', filteredItems);
  },

  loadFilters({ commit }) {
    return this.$api.filters.query().then(filters => {
      commit('SET_FILTERS', filters);
      saveToSessionStorage('filters', filters);
    });
  },
};

const mutations = {
  SET_ITEMS: (state, items) => {
    state.items = items;
  },

  SET_POPULAR_ITEMS: (state, items) => {
    state.popularItems = items;
  },

  SET_FILTERED_ITEMS: (state, payload) => {
    state.filteredItems = payload;
  },

  SET_FILTERS: (state, filters) => {
    state.filters = filters;
  },

  SET_SELECTED_CATEGORY: (state, newCategory) => {
    state.selectedCategory = newCategory;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
