import { ethers } from 'ethers';
import contractAbi from '@/assets/web3/auction.json';
import axiosInstance2 from '@/plugins/serviceA';
import { getLocation } from '@/helpers/getLocation';

const actions = {
  async placeBid({ rootGetters }, auctionData) {
    try {
      const checkPosition = await actions.checkPosition(auctionData, this.$jwt.getToken());
      console.log(checkPosition);
      const auctionId = await actions.getAuctionId(auctionData.nftId, this.$jwt.getToken());
      const bidHash = await actions.setBid(rootGetters, auctionId.auction_id, auctionData.bid);
      console.log(bidHash);
      await actions.purchase(auctionData, bidHash, this.$jwt.getToken());
    } catch (e) {
      console.error(e);
    }
  },

  async buyByFixprice({ rootGetters }, auctionData) {
    try {
      const checkPosition = await actions.checkPosition(auctionData, this.$jwt.getToken());
      console.log(checkPosition);
      const auctionId = await actions.getAuctionId(auctionData.nftId, this.$jwt.getToken());
      const bidHash = await actions.buyItem(rootGetters, auctionId.auction_id, auctionData.bid);
      console.log(bidHash);
      await actions.purchase(auctionData, bidHash, this.$jwt.getToken());
    } catch (e) {
      console.error(e);
    }
  },

  async getAuctionId(nftId, jwt) {
    const { data } = await axiosInstance2.get(
      `/v1/nft/${nftId}/auction-id`,
      {},
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      },
    );

    return data;
  },

  async checkPosition(auctionData, jwt) {
    const location = await getLocation();
    this.location = [];
    const { data } = await axiosInstance2.post(
      '/v1/nft/purchase',
      {
        nft_id: auctionData.nftId,
        step_id: 1,
        location: {
          lng: location.coords.latitude,
          lat: location.coords.longitude,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      },
    );

    return data;
  },

  async purchase(auctionData, hash, jwt) {
    const location = await getLocation();
    this.location = [];
    const { data } = await axiosInstance2.post('/v1/nft/purchase',
      {
        nft_id: auctionData.nftId,
        step_id: 2,
        location: {
          lng: location.coords.longitude,
          lat: location.coords.latitude,
        },
        transaction_hash: hash,
        price: {
          value: parseFloat(auctionData.bid),
        },
      },
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      },
    );
    return data;
  },

  /**
   * Ставка через контракт
   *
   * @param rootGetters
   * @param auctionId
   * @param bid
   * @returns {Promise<*>}
   */
  async setBid(rootGetters, auctionId, bid) {
    const web3 = await rootGetters['wallet/getWeb3Modal'].connect();
    const provider = new ethers.providers.Web3Provider(web3);
    const signer = provider.getSigner();
    const address = process.env.VUE_APP_SALE_NFT_ADDRESS;
    let contr = new ethers.Contract(address, contractAbi, provider);
    const result = await contr
      .connect(signer)
      .placeBid(auctionId, { value: ethers.utils.parseEther(bid.toString()) });

    return result.hash;
  },

  /**
   * Ставка через контракт
   *
   * @param rootGetters
   * @param auctionId
   * @param bid
   * @returns {Promise<*>}
   */
  async buyItem(rootGetters, auctionId, bid) {
    const web3 = await rootGetters['wallet/getWeb3Modal'].connect();
    const provider = new ethers.providers.Web3Provider(web3);
    const signer = provider.getSigner();
    const address = process.env.VUE_APP_SALE_NFT_ADDRESS;
    let contr = new ethers.Contract(address, contractAbi, provider);
    const result = await contr
      .connect(signer)
      .placeBid(auctionId, { value: ethers.utils.parseEther(bid.toString()) });

    return result.hash;
  },

  /**
   * Сохранение ставки в системе
   *
   * @param params
   * @param hash
   * @param jwt
   * @returns {Promise<any>}
   */
  async saveBid(params, hash, jwt) {
    const { data } = await axiosInstance2.post(
      'v1/nft/purchase',
      {
        operation_id: 7,
        nft_id: params.mintedNftId,
        transaction_hash: hash,
      },
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      },
    );

    return data;
  },
};

export default {
  namespaced: true,
  actions,
};
