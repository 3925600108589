import axios from 'axios';

const axiosInstance2 = axios.create({
  baseURL: `${process.env.VUE_APP_BASE_URL}/api/`,
});

axiosInstance2.interceptors.response.use(
  res => res,
  e => {
    const defaultMessage = 'Error with server connection';
    console.info(
      '%c ERROR with fetching data 🤬',
      'color: #eee; background-color: #ff2020',
      e?.response?.data?.error?.message || defaultMessage,
    );
    return Promise.reject(e);
  },
);

export default axiosInstance2;
