const actions = {
  async getSortings() {
    return {
      data: [
        {id: 1, name: 'Recently listed'},
        {id: 2, name: 'On sale'},
        {id: 3, name: 'Price: Low to High'},
        {id: 4, name: 'Price: High to Low'},
        {id: 5, name: 'Auction ending soon'},
        {id: 6, name: 'By distance (closest first)'},
      ],
    };
  },
};
export default {
  namespaced: true,
  actions,
};
