import { ethers } from 'ethers';
import contractAbi from '@/assets/web3/mintNft.json';
import axiosInstance2 from '@/plugins/serviceA';
import operationTypes from '@/store/modules/constants/operationTypes';
import router from '@/router';

const actions = {
  async mintNft({ rootGetters }, formData) {
    try {
      const dataFromUpload = await actions.uploadFile(formData, this.$jwt.getToken());
      try {
        const signHash = await actions.signFile(rootGetters, dataFromUpload.nft_ipfs_path);
        await actions.sendSignData(dataFromUpload.nft_id, signHash, this.$jwt.getToken());
        router.push({ path: '/create-auction', query: { nft_id: dataFromUpload.nft_id, type: 1 }});
      } catch (e) {
        await actions.rejectOperation(dataFromUpload.nft_id, this.$jwt.getToken());
        console.log(e);
      }
    } catch (e) {
      console.log(e);
    }
  },

  async uploadFile(dataObj, token) {
    let bodyFormData = new FormData();
    bodyFormData.append('file', dataObj.file);
    delete dataObj.file;
    bodyFormData.append('data', JSON.stringify(dataObj));

    const { data } = await axiosInstance2.post('v1/nft/upload', bodyFormData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    });

    return data;
  },

  async signFile(rootGetters, nftIpfsPath) {
    const web3 = await rootGetters['wallet/getWeb3Modal'].connect();
    const provider = new ethers.providers.Web3Provider(web3);
    const signer = provider.getSigner();
    const address = process.env.VUE_APP_MINTING_CONTRACT_ADDRESS;
    const auctionAddress = process.env.VUE_APP_SALE_NFT_ADDRESS;
    let contr = new ethers.Contract(address, contractAbi, provider);
    const result = await contr
      .connect(signer)
      .mintAndApproveMarketForSale(nftIpfsPath, auctionAddress);

    return result.hash;
  },

  async sendSignData(nftId, hash, token) {
    await axiosInstance2.post(
      '/v1/nft/transaction',
      {
        operation_id: operationTypes.mint.id,
        nft_id: nftId,
        transaction_hash: hash,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },

  async rejectOperation(nftId, token) {
    await axiosInstance2.post(
        `/v1/nft/${nftId}/mint-transaction`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
    );
  },
};

export default {
  namespaced: true,
  actions,
};
