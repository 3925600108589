const state = () => ({
  careers: [],
  currentRole: {},
});

const actions = {
  loadCareers({ commit }) {
    return this.$api.careers.query().then(careers => commit('SET_CAREERS', careers));
  },

  loadRole({ commit }, roleId) {
    return this.$api.careers.get(roleId).then(role => commit('SET_ROLE', role));
  },
};

const mutations = {
  SET_CAREERS: (state, careers) => {
    state.careers = careers;
  },

  SET_ROLE: (state, role) => {
    state.currentRole = role;
  },
};

const getters = {
  getCareersAmount: state => {
    let sum = 0;
    for (const key in state.careers) {
      sum += state.careers[key].length;
    }
    return sum;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
