import { createWebHistory, createRouter } from 'vue-router';
import { routes } from './routes';

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise(resolve => {
      if (savedPosition) {
        resolve(savedPosition);
      } else if (to.hash) {
        setTimeout(() => {
          resolve({ el: to.hash, behavior: 'smooth', left: 0, top: 0 });
        }, 500);
      } else {
        resolve({ left: 0, top: 0 });
      }
    });
  },
});

export default router;
