export const mainPageFilters = [
    {
        prop: 'explorePageItems',
        params: {
            selection: 'nft',
        },
    },
    {
        prop: 'mainPageCollections',
        params: {
            selection: 'collection',
            // collection_info: {
            //     type: 'top',
            // },
        },
    },
    {
        prop: 'mainPageRecentBids',
        params: {
            selection: 'nft',
            status_id: 7,
        },
    },
    {
        prop: 'mainPageRecentlyListed',
        params: {
            selection: 'nft',
            status_id: 6,
        },
    },
    {
        prop: 'mainPageNewlyMinted',
        params: {
            selection: 'nft',
            status_id: 8,
        },
    },
    {
        prop: 'mainPageRecentlySold',
        params: {
            selection: 'nft',
            status_id: 9,
        },
    },
];
