<template>
  <SimpleGrid
    v-if="
      (blockType === listTypes.find(value => value.code === 'nft').value ||
        blockType === listTypes.find(value => value.code === null).value) &&
        listType === 1
    "
    :items="usersNfts"
    :big-size-card="bigSizeCard"
    :small-size-collection="smallSizeCollection"
    :need-button="needButton"
  />
  <CollectionBlock
    v-if="
      blockType === listTypes.find(value => value.code === 'collection').value && listType === 1
    "
    :items="usersNfts"
    :small-size-collection="smallSizeCollection"
    :need-button="needButton"
  />
  <!--  todo Избавиться от магических чисел-->
  <MultipleItemsMap
    v-if="listType === 2"
    :center-coordinates="[30.326575, 59.940849]"
    :items="computedItems"
    :show-location="false"
  />
</template>

<script>
import SimpleGrid from '@/components/_UI/grids/SimpleGrid.vue';
import CollectionBlock from '@/components/_UI/grids/BigCardGrid.vue';
import MultipleItemsMap from '@/components/_UI/map/MultipleItemsMap.vue';

export default {
  name: 'CreatedSection',

  components: {
    MultipleItemsMap,
    SimpleGrid,
    CollectionBlock,
  },
  props: {
    usersNfts: {
      type: Array,
      required: true,
    },
    blockType: {
      type: Number,
      default: 1,
    },
    listType: {
      type: Number,
      default: 1,
    },
    listTypes: {
      type: Array,
      required: true,
    },
    listViewType: {
      type: Number,
      default: 1,
    },
    sorting: {
      type: Number,
      default: 1,
    },
    smallSizeCollection: {
      type: Boolean,
      default: false,
    },
    bigSizeCard: {
      type: Boolean,
      default: false,
    },
    needButton: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    computedItems() {
      return this.usersNfts.map(item => item.nft?.location);
    },
  },
  methods: {
    onClickListSwitcher(value) {
      alert(value);
    },
  },
};
</script>

<style scoped lang="sass">
@import "@/assets/sass/pages/_created.sass"
</style>
