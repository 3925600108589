const operationTypes = {
  mint: {
    id: 1,
    name: 'mint and approve',
    description: 'Minting and approving NFT',
  },
  createReserveAuction: {
    id: 2,
    name: 'create reserve auction',
    description: 'Creating reserve auction',
  },
  update: {
    id: 3,
    name: 'update reserve auction',
    description: 'Updating reserve auction',
  },
  cancel: {
    id: 4,
    name: 'cancel reserve auction',
    description: 'Canceling reserve auction',
  },
  finalize: {
    id: 5,
    name: 'finalize reserve auction',
    description: 'Finalizing reserve auction',
  },
  withdraw: {
    id: 6,
    name: 'withdraw for user',
    description: 'Withdrawing for user',
  },
  bid: {
    id: 7,
    name: 'place bid',
    description: 'Placing bid',
  },
  listing: {
    id: 8,
    name: 'create listing',
    description: 'Creating listing',
  },
  updateListing: {
    id: 9,
    name: 'update listing',
    description: 'Updating listing',
  },
  cancelListing: {
    id: 10,
    name: 'cancel listing',
    description: 'Canceling listing',
  },
  buyListedNft: {
    id: 11,
    name: 'buy listed nft',
    description: 'Buying listed nft',
  },
  withdrawProcessed: {
    id: 12,
    name: 'withdraw proceeds',
    description: 'Withdrawing proceeds from sold listed nfts',
  },
};

export default operationTypes;
