import QUERIES from '@/services/graphQueries';

const state = () => ({
  allUsernames: [],
  allItemsIds: [],
});

const getters = {
  doesItemExist: state => id => state.allItemsIds.some(item => item.id === id),
  doesUserExist: state => username => state.allUsernames.some(item => item.username === username),
};

const actions = {
  loadValidationParameters({ commit }) {
    QUERIES.getValidationParameters().then(response => {
      commit('SET_ALL_ITEMS_IDS', response.data.getItems);
      commit('SET_ALL_USERNAMES', response.data.getUsers);
    });
  },
};

const mutations = {
  SET_ALL_USERNAMES: (state, usernames) => {
    state.allUsernames = usernames;
  },

  SET_ALL_ITEMS_IDS: (state, ids) => {
    state.allItemsIds = ids;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
