<template>
  <div class="profile-preview"
       :style="style">
    <div class="profile-preview__avatar-block">
      <a :href="`/${userFollows.username}/created`">
        <div
          :class="[
            'profile-preview__avatar',
            otherAvatarClass ? 'profile-preview__avatar-userpage' : '',
          ]"
          :style="avatarSrc"
        ></div>
        <div style="text-align: center">
          <div class="profile-preview__name"
          >{{ userFollows.first_name ? userFollows.first_name : userFollows.id }}
          </div>
          <div class="profile-preview__nickname">
            @ {{ userFollows.username ? userFollows.username : 'nickname' }}
          </div>
        </div>
      </a>
      <div v-if="type === 'following'">
        <div class="profile-preview__follow-btn"
             @click="unfollow(userFollows.id)">UNFOLLOW</div>
      </div>
      <div v-else-if="type === 'followers'">
        <div class="profile-preview__follow-btn"
             @click="follow(userFollows.id)">FOLLOW</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'ProfilePreview',

  props: {
    image: {
      type: String,
      required: true,
    },
    avatar: {
      type: String,
      required: true,
    },
    otherAvatarClass: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: true,
    },
    userFollows: {
      type: Object,
      required: true,
    },
    userId: {
      type: Number,
      required: true,
    },
  },
  emits: ['unfollow', 'follow'],
  computed: {
    style() {
      return `background-image: linear-gradient(180deg, rgba(36, 36, 36, 0) 0%, #242424 100%), url(${this.image})`;
    },
    avatarSrc() {
      return `background-image: url(${this.avatar})`;
    },
  },
  methods: {
    ...mapActions({
      UNFOLLOW_USER: 'follow/UNFOLLOW_USER',
      FOLLOW_USER: 'follow/FOLLOW_USER',
    }),
    unfollow(id) {
      this.UNFOLLOW_USER(id);
      // this.$emit('unfollow');
    },
    follow(id) {
      this.FOLLOW_USER(id);
      // this.$emit('follow');
    },
  },
};
</script>

<style lang="sass">
@import "@/assets/sass/components/_profile-preview.sass"
</style>
