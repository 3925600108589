import QUERIES from '@/services/graphQueries';
import { createPattern } from '@/helpers/createGeoPattern';

const state = () => ({
  currentUser: {},
  currentUserSubscriptions: [],
  currentUserFollowers: [],
  currentUserOwnedItems: [],
  loggedUserFollowsCurrentUser: false,
});

const getters = {
  doesLoggedUserFollowsCurrentUser: state => state.loggedUserFollowsCurrentUser,
};

const actions = {
  loadUserInfo({ commit }, username) {
    return QUERIES.getCurrentUser(username)
      .then(response => {
        const user = { ...response.data.getUserByUsername };

        // eslint-disable-next-line no-unused-expressions
        user.banner_img_url
          ? (user.banner_url = `url(${user.banner_img_url})`)
          : (user.banner_url = createPattern(user.id));

        commit('SET_CURRENT_USER', user);
      })
      .catch(error =>
        // prettier-ignore
        console.info('%c ERROR in currentUser->loadUserInfo 🤬', 'color: #eee; background-color: #ff2020', error),
      );
  },
};

const mutations = {
  SET_CURRENT_USER(state, payload) {
    state.currentUser = { ...state.currentUser, ...payload };
  },
  SET_USERS_FOLLOWING_RELATIONS(state, relationStatus) {
    state.loggedUserFollowsCurrentUser = relationStatus;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
