import axios from 'axios';

const state = () => ({
  nftMetaData: {},
});

const mutations = {
  SET_METADATA: (state, res) => {
    state.nftMetaData = res.data;
  },
};

const actions = {
  async GET_METADATA({ commit }, link) {
    await axios
      .get(link)
      .then(res => commit('SET_METADATA', res))
      .catch(error => {
        console.log(error);
      });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
