import JwtService from '@/services/jwt.service';
import axios from '@/plugins/service';
import axiosInstance2 from "../plugins/serviceA";

class BaseApiService {}

export class ReadOnlyApiService extends BaseApiService {
  #resource;
  constructor(resource) {
    super();
    this.#resource = resource;
  }

  async query(config = {}) {
    const { data } = await axios.get(this.#resource, config);
    return data;
  }

  async get(id, config = {}) {
    const { data } = await axios.get(`${this.#resource}/${id}`, config);
    return data;
  }
}

export class CrudApiService extends ReadOnlyApiService {
  #resource;
  constructor(resource) {
    super(resource);
    this.#resource = resource;
  }

  async post(entity) {
    const { data } = await axios.post(this.#resource, entity);
    return data;
  }
}

export class CrudApiService2 extends ReadOnlyApiService {
  #resource;
  constructor(resource) {
    super(resource);
    this.#resource = resource;
  }

  async get(id, config = {}) {
    const { data } = await axiosInstance2.get(`${this.#resource}/${id}`, config);
    return data;
  }
}

export class AuthApiService extends BaseApiService {
  setAuthHeader() {
    const token = JwtService.getToken();
    axiosInstance2.defaults.headers.common.Authorization = token ? `Bearer ${token}` : '';
  }

  async auth(params) {
    const { data } = await axiosInstance2.post('auth', params);
    return data;
  }

  async signUpAddress(params) {
    const { data } = await axiosInstance2.post('v1/auth/sign-up-address', params);
    return data;
  }

  async signInAddress(params) {
    const { data } = await axiosInstance2.post('v1/auth/sign-in-address', params);
    return data;
  }

  async logout() {
    const { data } = await axiosInstance2.delete('logout');
    return data;
  }
}
