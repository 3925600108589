const typesOfCollection = [
  {
    text: 'TRENDING',
    value: 'top',
  },
  {
    text: 'TOP',
    value: 'trending',
  },
];

export default typesOfCollection;
