import { gql } from '@apollo/client';
import apollo from '@/apollo';

const QUERIES = {
  // eslint-disable-next-line max-lines-per-function
  getCurrentItem: itemId => {
    const query = gql`
      query getItemById($id: ID!) {
        getItemById(id: $id) {
          id
          name
          img_url
          description
          price
          category
          likes
          created_at
          updated_at
          attachments
          software
          size
          tags
          country
          city
          country_code
          author {
            id
            name
            avatar_url
            username
            followers {
              id
            }
          }
          owner {
            id
            name
            avatar_url
            username
            followers {
              id
            }
          }
          bids {
            price
            date
            user {
              id
              avatar_url
              username
            }
          }
        }
      }
    `;

    const variables = { id: itemId };

    return apollo.query({ query, variables });
  },

  // eslint-disable-next-line max-lines-per-function
  getProfile: id => {
    const query = gql`
      query getUserById($id: ID!) {
        getUserById(id: $id) {
          id
          country_code
          created_at
          email
          avatar_url
          name
          username
          subscriptions {
            id
          }
          owned_items {
            id
            name
            img_url
            description
            price
            author {
              id
              name
              avatar_url
              username
            }
          }
        }
      }
    `;

    const variables = { id };

    return apollo.query({ query, variables });
  },

  // eslint-disable-next-line max-lines-per-function
  getCurrentUser: username => {
    const query = gql`
      query getUserByUsername($username: String!) {
        getUserByUsername(username: $username) {
          id
          name
          banner_img_url
          avatar_url
          bio
          likes_number
          country
          country_code
          created_items {
            id
            name
            img_url
            description
            price
            author {
              id
              name
              username
              avatar_url
            }
          }
          owned_items {
            id
            name
            img_url
            description
            price
            author {
              id
              name
              username
              avatar_url
            }
          }
          subscriptions {
            id
            name
            username
            avatar_url
          }
          followers {
            id
            name
            username
            avatar_url
          }
          socials {
            slug
            link
          }
        }
      }
    `;

    const variables = { username };

    return apollo.query({ query, variables });
  },

  getSellerCards: () => {
    const query = gql`
      query getUsers {
        getUsers {
          id
          avatar_url
          name
          banner_img_url
          username
          sales
          verified
          owned_items {
            id
          }
          followers {
            id
          }
        }
      }
    `;

    return apollo.query({ query });
  },

  // eslint-disable-next-line max-lines-per-function
  getTopUsers: () => {
    const query = gql`
      query getUsers {
        topCollectors: getUsers {
          id
          avatar_url
          name
          banner_img_url
          username
          sales
          owned_items {
            id
            price
          }
        }

        topArtists: getUsers {
          id
          avatar_url
          name
          banner_img_url
          username
          sales
          created_items {
            id
            price
          }
        }
      }
    `;

    return apollo.query({ query });
  },

  getValidationParameters: () => {
    const query = gql`
      query getParameters {
        getItems {
          id
        }
        getUsers {
          username
        }
      }
    `;

    return apollo.query({ query });
  },
};

export default QUERIES;
