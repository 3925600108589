import { createStore } from 'vuex';
import itemsData from './modules/items.store';
import usersData from './modules/usersData.store';
import currentUser from './modules/currentUser.store';
import currentItem from './modules/currentItem.store';
import auctionCurrentItem from './modules/auction/auctionCurrentItem.store';
import loggedUser from './modules/loggedUser.store';
import routerSettings from './modules/routerSettings.store';
import postsData from './modules/postsData.store';
import careers from './modules/careers.store';
import help from './modules/help.store';
import userSettings from './modules/userSettings.store';
import appSettings from './modules/appSettings.store';
import wallet from './modules/wallet';
import mintNft from './modules/nft/mintNft.store';
import bidAuction from './modules/auction/bidAuction.store';
import cancelAuction from './modules/auction/cancelAuction.store';
import createAuction from './modules/auction/createAuction.store';
import updateAuction from './modules/auction/updateAuction.store';
import finalizeAuction from './modules/auction/finalizeAuction.store';
import postPosition from './modules/auction/postPosition.store';
import withdrawForUser from './modules/auction/withdrawForUser.store';
import getNft from './modules/nft/getNft.store';
import getFilters from './modules/getFilters.store';
import collection from './modules/collection';
import category from './modules/category';
import pinata from './modules/pinata.store';
import follow from '@/store/modules/follow.store';
import sorting from './modules/constants/sorting';
import directory from './modules/directory.store';
import VuexService from '@/plugins/vuexService';

const actions = {
  init({ dispatch }) {
    return Promise.all([dispatch('routerSettings/loadValidationParameters')]);
  },
};

const store = createStore({
  plugins: [VuexService],
  actions,
  modules: {
    wallet,
    itemsData,
    usersData,
    currentUser,
    currentItem,
    auctionCurrentItem,
    loggedUser,
    routerSettings,
    postsData,
    careers,
    help,
    userSettings,
    appSettings,
    mintNft,
    bidAuction,
    cancelAuction,
    createAuction,
    finalizeAuction,
    postPosition,
    updateAuction,
    withdrawForUser,
    getFilters,
    collection: collection,
    category: category,
    getNft: getNft,
    pinata: pinata,
    sorting: sorting,
    follow: follow,
    directory: directory,
  },
});

export default store;
