import ccc from '@/store/modules/mock/auction-example.js';

const state = () => ({
  itemDetails: {},
});

const actions = {
  LOAD_ITEM_DETAILS({ commit }) {
    commit('SET_DETAILS');
  },
};

const mutations = {
  SET_DETAILS: state => {
    state.itemDetails = ccc;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
