const timePeriods = [
  { text: '1h', value: 1 },
  { text: '6h', value: 2 },
  { text: '24h', value: 3 },
  { text: '7d', value: 4 },
  { text: '30d', value: 5 },
  { text: 'All', value: 6 },
];

export default timePeriods;
