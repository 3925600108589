import {
  saveToSessionStorage,
  checkInSessionStorage,
  getFromSessionStorage,
} from '@/helpers/storageHandler';

const state = () => ({
  faq: checkInSessionStorage('faq') ? getFromSessionStorage('faq') : [],
});

const actions = {
  loadFAQ({ commit }) {
    return this.$api.help.query().then(helpSections => {
      const sectionIds = [];

      helpSections.forEach(item => {
        sectionIds.push(item.sectionId);
      });

      commit('SET_FAQ', helpSections);
      saveToSessionStorage('faq', helpSections);
    });
  },
};

const mutations = {
  SET_FAQ: (state, payload) => {
    state.faq = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
