<template>
  <div class="main-page">
    <!--  todo: Вынести в отдельный компонент  -->
    <div class="main-page-collections main-page-block container">
      <div class="main-page-collections-top-section">
        <div class="top-section-switcher">
          <TextSwitcher v-model:state="typeOfCollection"
                        :values="typesOfCollection" />
        </div>
        <div class="top-section-buttons"
             style="display: none;">
          <TextSwitcher
            v-model:state="currentTimePeriod"
            :values="timePeriods"
            class="top-section-buttons-time-switcher"
          />
          <button class="top-section-buttons-button"
          ><a
            :href="`/explore?listType=1&blockType=3&filterDropdown=${typeOfCollection}&currentTimePeriod=${currentTimePeriod}`"
          >VIEW ALL</a
          ></button
          >
        </div>
      </div>

      <div class="main-page-collections-wrapper">
        <div class="main-page-collections-inner">
          <div
            class="collections-section collections-section-header collections-section-header-hidden"
          >
            <div class="collections-cell collections-cell-point"></div>
            <div class="collections-cell collections-cell-collection">collection</div>
            <div class="collections-cell collections-cell-price">Floor price</div>
            <div class="collections-cell collections-cell-volume">Volume</div>
          </div>

          <div
            v-for="(collection, index) in mainPageCollections.slice(0, 4)"
            :key="collection.collection.id"
            class="collections-section collections-section-body"
          >
            <div class="collections-cell collections-cell-point">{{ index + 1 }}</div>
            <div class="collections-cell collections-cell-hidden">collection</div>
            <a
              class="collections-cell collections-cell-collection"
              :href="`${collection?.collection?.creator?.username}/created?collectionId=${collection?.collection?.id}`"
            >
              <img
                class="collections-section-avatar"
                src="@/assets/img/icons/big-bad-bears.png"
                alt="collection icon"
              />
              <span>{{ collection.collection.name }}</span>
            </a>
            <div class="collections-cell collections-cell-price collections-cell-hidden"
            >Floor price</div
            >
            <div class="collections-cell collections-cell-volume collections-cell-hidden"
            >Volume</div
            >
            <div class="collections-cell collections-cell-price">-----</div>
            <div class="collections-cell collections-cell-volume">-----</div>
          </div>
        </div>

        <div class="main-page-collections-inner">
          <div
            class="collections-section collections-section-header collections-section-header-hidden"
          >
            <div class="collections-cell collections-cell-point"></div>
            <div class="collections-cell collections-cell-collection">collection</div>
            <div class="collections-cell collections-cell-price">Floor price</div>
            <div class="collections-cell collections-cell-volume">Volume</div>
          </div>

          <div
            v-for="(collection, index) in mainPageCollections.slice(4, 8)"
            :key="collection.collection.id"
            class="collections-section collections-section-body"
          >
            <div class="collections-cell collections-cell-point">{{ index + 5 }}</div>
            <div class="collections-cell collections-cell-hidden">collection</div>
            <a
              class="collections-cell collections-cell-collection"
              :href="`${collection?.collection?.creator?.username}/created?collectionId=${collection?.collection?.id}`"
            >
              <img
                class="collections-section-avatar"
                src="@/assets/img/icons/big-bad-bears.png"
                alt="collection icon"
              />
              <span>{{ collection.collection.name }}</span>
            </a>
            <div class="collections-cell collections-cell-price collections-cell-hidden"
            >Floor price</div
            >
            <div class="collections-cell collections-cell-volume collections-cell-hidden"
            >Volume</div
            >
            <div class="collections-cell collections-cell-price">-----</div>
            <div class="collections-cell collections-cell-volume">-----</div>
          </div>
        </div>
      </div>
    </div>

    <div class="main-page-map main-page-block container">
      <h2 class="main-page-map-title">PNFT worldwide</h2>
      <MultipleItemsMap
        v-if="mapCenter?.length"
        :min-height="true"
        :center-coordinates="mapCenter"
        :items="filterNft(explorePageItems)"
        :show-location="false"
      />
    </div>

    <div v-if="mainPageRecentBids?.length"
         class="main-page-block container">
      <CarouselSlider
        title="Recent bids"
        :items="mainPageRecentBids"
        :carousel-card="true"
        view-all-link="explore?filterDropdown=7"
      />
    </div>

    <div v-if="mainPageRecentlyListed?.length"
         class="main-page-block container">
      <CarouselSlider
        title="Recently listed"
        :items="mainPageRecentlyListed"
        :carousel-card="true"
        view-all-link="explore?filterDropdown=6"
      />
    </div>

    <div v-if="mainPageNewlyMinted?.length"
         class="main-page-block container">
      <CarouselSlider
        title="Newly minted"
        :items="mainPageNewlyMinted"
        :carousel-card="true"
        view-all-link="explore?filterDropdown=8"
      />
    </div>

    <div class="main-page-subscribe main-page-block container">
      <img
        class="main-page-subscribe-icon"
        src="@/assets/img/icons/main-page-letter.svg"
        alt="letter icon"
      />
      <h3 class="main-page-subscribe-title">Never miss a drop!</h3>
      <p class="main-page-subscribe-text"
      >Subscribe to our exclusive drop list and be the first to know about upcoming NFT drops.</p
      >
      <div class="main-page-subscribe-form">
        <input class="main-page-subscribe-input"
               type="text"
               placeholder="Your email address" />
        <button class="main-page-subscribe-button">Subscribe</button>
      </div>
    </div>

    <div v-if="mainPageRecentlySold?.length"
         class="main-page-block container">
      <CarouselSlider
        title="Recently sold"
        :items="mainPageRecentlySold"
        :carousel-card="true"
        view-all-link="explore?filterDropdown=9"
      />
    </div>

    <div class="main-page-categories main-page-block">
      <div class="container">
        <h2 class="main-page-categories-title">Categories</h2>

        <div class="main-page-categories-wrapper">
          <div
            v-for="category in allCategories"
            :key="category.name"
            class="main-page-categories-category"
          >
            <img
              class="main-page-categories-category-image"
              :src="category.img"
              alt="category image"
            />
            <span class="main-page-categories-category-name"
            ><a :href="`explore?category={&quot;${category.id}&quot;:true}`">{{
              category.name
            }}</a></span
            >
          </div>
        </div>
      </div>
    </div>

    <div class="main-page-creators main-page-block container">
      <CarouselFollowers :items="followers"
                         :title="'Featured creators'"
                         view-all-link="creators"/>
    </div>
  </div>
</template>

<script>
import CarouselFollowers from '@/components/_UI/CarouselFollowers.vue';
import CarouselSlider from '@/components/_UI/CarouselSlider.vue';
import MultipleItemsMap from '@/components/_UI/map/MultipleItemsMap.vue';
import TextSwitcher from '@/components/_UI/TextSwitcher.vue';
import { mapState, mapActions } from 'vuex';
import { allCategories } from '@/store/modules/constants/mainPageCategories.js';
import { followers } from '@/store/modules/constants/followers.js';
import { getLocation } from '@/helpers/getLocation';
import timePeriods from '@/store/modules/constants/timePeriods';
import typesOfCollection from '@/store/modules/constants/typesOfCollection';
import {mainPageFilters} from '@/store/modules/constants/mainPageFilters';

export default {
  name: 'MainPage',

  components: {
    CarouselFollowers,
    MultipleItemsMap,
    CarouselSlider,
    TextSwitcher,
  },
  data() {
    return {
      typeOfCollection: 'top',
      typesOfCollection: typesOfCollection,
      currentTimePeriod: 1,
      timePeriods: timePeriods,
      allCategories: allCategories,
      followers: followers,
      mapCenter: [],
    };
  },
  computed: {
    ...mapState('getNft', {
      mainPageCollections: state => state.mainPageCollections,
      explorePageItems: state => state.explorePageItems,
      mainPageRecentBids: state => state.mainPageRecentBids,
      mainPageRecentlyListed: state => state.mainPageRecentlyListed,
      mainPageNewlyMinted: state => state.mainPageNewlyMinted,
      mainPageRecentlySold: state => state.mainPageRecentlySold,
    }),
  },

  watch: {
    typeOfCollection: {
      handler() {
        this.GET_USERS_NFTS({
          prop: 'mainPageCollections',
          params: {
            selection: 'collection',
            // collection_info: {
            //   type: this.typeOfCollection,
            // },
          },
        })
      },
    },
  },

  async created() {
    for (let params of mainPageFilters) {
      await this.GET_USERS_NFTS(params);
    }
    const location = await getLocation();
    this.mapCenter = [location.coords.longitude, location.coords.latitude];
  },
  methods: {
    ...mapActions({
      GET_USERS_NFTS: 'getNft/GET_USERS_NFTS',
    }),
    select(id, data) {
      this[data] = id;
    },
    filterNft(arr) {
      return arr.filter(item => item.nft);
    },
  },
};
</script>

<style lang="sass">
@import "@/assets/sass/pages/_main-page.sass"
</style>
