import { ethers } from 'ethers';
import contractAbi from '@/assets/web3/auction.json';
import axiosInstance2 from '@/plugins/serviceA';

const actions = {
  /**
   * Установка новой цены аукциона, работает только если не было сделано ни одной ставки
   *
   * @param rootGetters
   * @param auctionData
   * @returns {Promise<void>}
   */
  async cancelReserveAuction({ rootGetters }, auctionData) {
    try {
      const hash = await actions.setReserveAuction(rootGetters, auctionData.auctionId);
      const auctionId = await actions.saveReserveAuction(
        auctionData.mintedNftId,
        hash,
        this.$jwt.getToken(),
      );
      console.log(`Аукцион успешно создан. Идентификатор: ${auctionId}`)
    } catch (e) {
      console.error(e);
    }
  },

  /**
   * Обновление аукциона через контракт
   *
   * @param rootGetters
   * @param auctionId
   * @returns {Promise<*>}
   */
  async setReserveAuction(rootGetters, auctionId) {
    const web3 = await rootGetters['wallet/getWeb3Modal'].connect();
    const provider = new ethers.providers.Web3Provider(web3);
    const signer = provider.getSigner();
    const address = process.env.VUE_APP_MINTING_CONTRACT_ADDRESS;
    let contr = new ethers.Contract(address, contractAbi, provider);
    const result = await contr.connect(signer).cancelReserveAuction(auctionId);

    return result.hash;
  },

  /**
   * Сохранение ставки в системе
   *
   * @param mintedNftId
   * @param hash
   * @param jwt
   * @returns {Promise<any>}
   */
  async saveReserveAuction(mintedNftId, hash, jwt) {
    const { data } = await axiosInstance2.post(
      'v1/nft/auction/cancel-reserve-auction',
      {
        nft_id: mintedNftId,
        transaction_hash: hash,
      },
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      },
    );

    return data;
  },
};

export default {
  namespaced: true,
  actions,
};
