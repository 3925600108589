import axiosInstance2 from '@/plugins/serviceA';

const state = () => ({
  profileSettings: {},
});

const actions = {
  async GET_USER_SETTINGS({ commit }) {
    if (!this.$jwt.getToken()) {
      return;
    }
    await axiosInstance2
      .get(
        'v1/user',
        {},
        {
          headers: {
            Authorization: `Bearer ${this.$jwt.getToken()}`,
          },
        },
      )
      .then(res => commit('SET_USER_SETTINGS', res))
      .catch(error => {
        // todo Проработать алерты в дизайне
        console.error(error);
      });
  },
  async SAVE_USER_SETTINGS(_, data) {
    await axiosInstance2
      .put('v1/user', data, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${this.$jwt.getToken()}`,
        },
      })
      .catch(error => {
        // todo Проработать алерты в дизайне
        console.error(error);
      });
  },
};

const mutations = {
  SET_USER_SETTINGS: (state, res) => {
    state.profileSettings = res.data;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
