<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import { setAuth } from '@/helpers/setAuth';
import { mapActions, mapState } from 'vuex';

const DefaultLayout = defineAsyncComponent(() => import('./layouts/DefaultLayout.vue'));
const MapLayout = defineAsyncComponent(() => import('./layouts/MapLayout.vue'));
const AboutLayout = defineAsyncComponent(() => import('./layouts/AboutLayout.vue'));
const MainPageLayout = defineAsyncComponent(() => import('./layouts/MainPageLayout.vue'));

export default {
  components: {
    DefaultLayout,
    MapLayout,
    AboutLayout,
    MainPageLayout,
  },

  computed: {
    ...mapState('wallet', ['isEthereumDetected', 'isConnected', 'activeAccount']),
    ...mapState('userSettings', {
      profileSettings: state => state.profileSettings,
    }),
    layout() {
      return `${this.$route.meta.layout || 'default'}-layout`;
    },
  },

  created() {
    if (this.$jwt.getToken()) {
      setAuth(this.$store);
    }
    this.$store.dispatch('routerSettings/loadValidationParameters');
    this.GET_USER_SETTINGS();
  },
  methods: {
    ...mapActions({
      GET_USER_SETTINGS: 'userSettings/GET_USER_SETTINGS',
    }),
  },
};
</script>

<style>
@font-face {
  font-family: 'Inter';
  src: local('Inter'), url(./assets/font/Inter-Regular.ttf) format('truetype');
}
</style>
