export const followers = [
  {
    id: 1,
    image: "'/img/following/bg.png'",
    avatar: "'/img/following/avatar.png'",
    first_name: 'Mick',
    username: 'AwesomeMick',
  },
  {
    id: 2,
    image: "'/img/following/bg2.png'",
    avatar: "'/img/following/avatar2.png'",
    first_name: 'John',
    username: 'Terminator',
  },
  {
    id: 3,
    image: "'/img/following/bg3.png'",
    avatar: "'/img/following/avatar3.png'",
    first_name: 'Ellen',
    username: 'Alien',
  },
  {
    id: 4,
    image: "'/img/following/bg4.png'",
    avatar: "'/img/following/avatar4.png'",
    first_name: 'Alex',
    username: 'Robocop',
  },
  {
    id: 5,
    image: "'/img/following/bg5.png'",
    avatar: "'/img/following/avatar5.png'",
    first_name: 'Bruce',
    username: 'Betman',
  },
  {
    id: 6,
    image: "'/img/following/bg6.png'",
    avatar: "'/img/following/avatar6.png'",
    first_name: 'Stanley',
    username: 'Themask',
  },
];
