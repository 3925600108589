import axiosInstance2 from '@/plugins/serviceA';
import router from '@/router';

const state = () => ({
  allCollections: [],
});

const mutations = {
  SET_ALL_COLLECTINS: (state, res) => {
    state.allCollections = res.data;
  },
};

const actions = {
  async createCollection(_, formData) {
    try {
      const newCollection = await axiosInstance2.post('v1/collection', formData, {
        headers: {
          Authorization: `Bearer ${this.$jwt.getToken()}`,
        },
      });
      console.log('Коллекция создана успешно!')

      router.push({ path: '/upload-item', query: {collectionId: newCollection.data.id} });
    } catch (e) {
      console.log(e);
    }
  },
  async getCollection(_, formData) {
    try {
      const result = await axiosInstance2.get('v1/collection/all', formData, {
        headers: {
          Authorization: `Bearer ${this.$jwt.getToken()}`,
        },
      });

      return result;
    } catch (e) {
      console.log(e);
    }
  },

  // for filters
  async GET_ALL_COLLECTIONS({ commit }, name) {
    await axiosInstance2
      .get(`v1/collection/filter?name=${name}`, {
        headers: {
          Authorization: `Bearer ${this.$jwt.getToken()}`,
        },
      })
      .then(res => commit('SET_ALL_COLLECTINS', res))
      .catch(error => {
        console.log(error);
      });
  },
};
export default {
  namespaced: true,
  actions,
  state,
  mutations,
};
