import { getLocation } from '@/helpers/getLocation.js';

const state = () => ({
  locationMessage: 'Getting your location...',
  geoErrorCode: 0,
});

const actions = {
  async locateMe({ commit }) {
    try {
      const location = await getLocation();

      commit('SET_LOCATION_MESSAGE', `${location.coords.latitude}, ${location.coords.longitude}`);
    } catch (e) {
      commit('SET_GEO_ERROR_CODE', e.code);
      commit('SET_LOCATION_MESSAGE', e.message);
    }
  },
};

const mutations = {
  SET_LOCATION_MESSAGE: (state, location) => {
    state.locationMessage = location;
  },

  SET_GEO_ERROR_CODE: (state, code) => {
    state.geoErrorCode = code;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
