<template>
  <main class="container error-page">
    <img src="@/assets/img/404.png"
         alt="error 404"
         class="error-page__img" />
    <p class="error-page__description">
      oops... It looks like there is no such page. You can go back to the
      <router-link :to="{ name: 'MainPage' }"
                   class="error-page__main-link">main page</router-link>
    </p>
  </main>
</template>

<style lang="sass">
@import "@/assets/sass/pages/_404.sass"
</style>
