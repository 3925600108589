import bbb1 from '@/assets/img/icons/big-bad-bears.png';
import bbb2 from '@/assets/img/icons/fighter.png';

const ccc = {
  liked: false,
  name: 'Cherry Bear',
  prices: [
    {
      title: 'price',
      cost: '0.2 ETH',
      currency: '$256.6',
    },
    {
      title: 'Higest bid',
      date: '05.24.2023',
      cost: '~0.001 wETH',
      currency: '$0.01',
    },
  ],
  'last-sale-price-cost': '0.38 ETH',
  'last-sale-price-currency': '$487.76',
  'about-users': [
    {
      title: 'item creator',
      'avatar-link': bbb2,
      name: 'Beeple',
      link: '@dangy_boi',
    },
    {
      title: 'collection',
      'avatar-link': bbb1,
      name: 'BigBadBears',
    },
    {
      title: 'current owner',
      'avatar-link': bbb2,
      name: 'XfighterX',
      link: '@xxxfighter',
    },
  ],
  mapCenter: [-33.92148, 151.185352],
  cityName: 'Sidney ',
  tags: ['Social', 'Art', 'Bear'],
  mintedOn: '23 Jan 2023',
  updated: '24 Jan 2023',
  creatorFee: '10%',
  description:
    '80 days around the world, we’ll find a pot of gold just sitting where the rainbow’s ending. Time — we’ll fight against the time, and we’ll fly on the white wings of the wind. 80 days around the world, no we won’t say a word before the ship is really back. Round, round, all around the world. Round, all around the world. Round, all around the world. Round, all around the world.',
  'content-type': 'Explicit & Sensitive Content',
  properties: [
    {
      entity: 'Class',
      name: 'Fighter',
    },
    {
      entity: 'Style',
      name: 'Berzerk',
    },
    {
      entity: 'Clan',
      name: 'MemeLords',
    },
  ],
  levels: [
    {
      name: 'Badassnes',
      value: 4,
      highest_value: 8,
    },
    {
      name: 'Transparency',
      value: 90,
      highest_value: 100,
    },
  ],
  stats: [
    {
      name: 'Speed',
      value: 3,
      highest_value: 10,
    },
    {
      name: 'Wins',
      value: 12,
      highest_value: 12,
    },
  ],
  history: [
    {
      avatar: '#',
      code: '0x150830DB0E0A31c495f4D52b699D9fCcaD380010',
      action: 'purchased for',
      'is-link': true,
      link: '#',
      date: '2023-04-12T16:20:54.068609Z',
      cost: '0.048 ETH',
      currency: '$66',
    },
    {
      avatar: '#',
      code: '0x150830DB0E0A31c495f4D52b699D9fCcaD380010',
      action: 'listed for',
      'is-link': false,
      link: '#',
      date: '2023-04-12T17:20:54.068609Z',
      cost: '0.048 ETH',
      currency: '$66',
    },
    {
      avatar: '#',
      code: '0x150830DB0E0A31c495f4D52b699D9fCcaD380010',
      action: 'listed for',
      'is-link': false,
      link: '#',
      date: '2023-04-12T17:20:54.068609Z',
      cost: '0.039 ETH',
      currency: '$50',
    },
    {
      avatar: '#',
      code: '0x150830DB0E0A31c495f4D52b699D9fCcaD380010',
      action: 'minted',
      'is-link': true,
      link: '#',
      date: '2023-04-12T15:20:54.068609Z',
      cost: '0.024 ETH',
      currency: '$45',
    },
  ],
};

export default ccc;
