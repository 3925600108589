<template>
  <div class="following-grid">
    <ProfilePreview
      v-for="userFollows in follows"
      :key="userFollows.id"
      :user-id="userFollows.id"
      :user-follows="userFollows"
      :image="'/img/following/bg.png'"
      :avatar="'/img/following/avatar.png'"
      :type="type"
    />
  </div>
</template>

<script>
import ProfilePreview from '@/components/user-page/ProfilePreview';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'FollowingPage',

  components: { ProfilePreview },
  props: {
    type: {
      type: String,
      required: false,
    },
    userId: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState('follow', {
      follows: state => state.follows,
    }),
  },
  created() {
    if (this.type === 'following') {
      this.GET_FOLLOWS_OF_USER();
    } else if (this.type === 'followers') {
      this.GET_USERS_FOLLOWERS();
    }
  },
  methods: {
    //todo Поднять выше, получение данных
    ...mapActions({
      GET_FOLLOWS_OF_USER: 'follow/GET_FOLLOWS_OF_USER',
      GET_USERS_FOLLOWERS: 'follow/GET_USERS_FOLLOWERS',
    }),
  },
};
</script>

<style lang="sass" scoped>
.following-grid
  display: grid
  justify-content: center
  gap: 20px
  grid-template-columns: repeat(auto-fill, 400px)
  margin-bottom: 567px

  @media only screen and (max-width: 1700px)
    grid-template-columns: repeat(4, 300px)
    grid-auto-rows: auto

  @media only screen and (max-width: 1400px)
    grid-template-columns: repeat(3, 350px)

  @media only screen and (max-width: 1200px)
    grid-template-columns: repeat(3, 300px)

  @media only screen and (max-width: 1100px)
    grid-template-columns: repeat(2, 400px)

  @media only screen and (max-width: 900px)
    grid-template-columns: repeat(2, 300px)

  @media only screen and (max-width: 800px)
    grid-template-columns: repeat(2, 300px)

  @media only screen and (max-width: 700px)
    grid-template-columns: repeat(1, 400px)

  @media only screen and (max-width: 520px)
    grid-template-columns: repeat(1, 330px)
</style>
