<template>
  <button
    :class="['btn', 'btn--rounded']"
    :disabled="disabled"
    :type="type"
    @click="$emit('click')"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'BaseButton',

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },

    type: {
      type: String,
      default: 'button',
    },
  },

  emits: ['click'],
};
</script>
