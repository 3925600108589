export default {
  AUTH: 'auth',
  CAREERS: 'careers',
  FAQ: 'help',
  FILTERS: 'filters',
  POSTS: 'posts',
  ITEMS: 'items',
  USERS: 'users',
  ACTIVITY: 'activity',
  STATEMENT: 'statement',
  SETTINGS: 'settings',
  USER: 'user',
};
