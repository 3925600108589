import axiosInstance2 from '@/plugins/serviceA';

const actions = {
  /**
   * Отзыв аукциона
   *
   * @returns {Promise<any>}
   */
  async withdrawReserveAuction() {
    try {
      const { data } = await axiosInstance2.post(
        'v1/nft/auction/withdraw-for-user',
        {},
        {
          headers: {
            Authorization: `Bearer ${this.$jwt.getToken()}`,
          },
        },
      );

      return data;
    } catch (e) {
      console.error(e);
    }
  },
};

export default {
  namespaced: true,
  actions,
};
