<template>
  <a v-if="collections?.id"
     :href="`?collectionId=${collections?.id}`">
    <div :class="['main-view', smallSizeCollection ? 'small-size' : '']">
      <div class="photo-block">
        <div v-for="path in computedCollections"
             :key="path"
             class="photo-preview">
          <img
            v-if="typeof path === 'string'"
            :src="`${pinataAdress}${path?.replace('ipfs://', '')}`"
            class="photo"
            crossorigin="anonymous"
          />
          <div v-else
               class="photo photo-empty"></div>
        </div>
      </div>

      <div class="info-block">
        <div class="info-block-left-side">
          <div class="collection-title">{{ collections.name }}</div>
          <div class="under-title-info">
            <div class="collection-type">Collection</div>
            <NftAuthor
              :data="{
                avatar: collections?.creator?.download_avatar_path,
                username: collections?.creator?.username,
              }"
            />
          </div>
        </div>

        <button v-if="needButton"
                class="add-collection-btn"
                @click="addItem">ADD ITEM</button>
      </div>
    </div>
  </a>
</template>

<script>
import NftAuthor from '@/components/_UI/viewCardComponent/NftAuthor.vue';
export default {
  name: 'ViewCollectionComponent',

  components: {
    NftAuthor,
  },
  props: {
    collections: {
      type: Object,
      required: true,
    },
    smallSizeCollection: {
      type: Boolean,
      default: false,
    },
    needButton: {
      type: Boolean,
      default: true,
    },
  },
  data: function () {
    return {
      pinataAdress: process.env.VUE_APP_PINATA_ADRESS,
      pinataToken: process.env.VUE_APP_PINATA_TOKEN,
    };
  },
  computed: {
    // it is for empty collection, created empty div
    computedCollections() {
      if (!this.collections.file_paths) {
        return 4;
      } else if (this.collections.file_paths.length === 1) {
        let arr = this.collections.file_paths.slice();
        arr.length = 4;
        return arr.fill(1, 1);
      } else if (this.collections.file_paths.length === 2) {
        console.log(this.collections.file_paths[this.collections.file_paths.length - 2]);
        let arr = this.collections.file_paths.slice();
        arr.length = 4;
        return arr.fill(1, 2);
      } else if (this.collections.file_paths.length === 3) {
        let arr = this.collections.file_paths.slice();
        arr.length = 4;
        return arr.fill(1, 3);
      }
      return this.collections.file_paths.slice(-4);
    },
  },
  methods: {
    addItem() {
      this.$router.push({ path: '/upload-item' });
    },
  },
};
</script>

<style lang="sass" scoped>
.main-view
  display: flex
  flex-direction: column
  justify-content: space-between
  width: 100%
  height: 632px
  max-width: 540px
  background: #242424
  border-radius: 15px

.small-size
  max-width: 400px
  height: 485px

  @media only screen and (max-width: 1320px)
    height: inherit

  .photo-block
    .photo-preview
      min-height: 170px
      max-height: 170px

      @media only screen and (max-width: 1320px)
        min-height: 120px
        max-height: 120px

      @media only screen and (max-width: 1070px)
        min-height: 170px
        max-height: 170px

      @media only screen and (max-width: 920px)
        min-height: 120px
        max-height: 120px

      @media only screen and (max-width: 700px)
        min-height: 170px
        max-height: 170px

      @media only screen and (max-width: 530px)
        min-height: 136px
        max-height: 136px

.photo-block
  display: grid
  grid-template-columns: repeat(2, 1fr)
  padding: 20px
  gap: 20px
  width: 100%
  height: 100%

  @media only screen and (max-width: 1320px)
    height: 300px

  @media only screen and (max-width: 1070px)
    height: 400px

  @media only screen and (max-width: 920px)
    height: 300px

  @media only screen and (max-width: 700px)
    height: 400px

  @media only screen and (max-width: 530px)
    height: 330px

.photo-preview
  min-height: 240px
  max-height: 240px
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  background-color: #404040
  margin-bottom: -15px
  z-index: 2
  position: relative
  border-radius: 15px
  overflow: hidden

  .photo
    max-width: 240px
    max-height: 240px

    &-empty
        background-image: url(~@/assets/img/icons/letter.svg)
        background-repeat: no-repeat
        background-position: center

.collection-title
  margin-bottom: 8.5px
  font-style: normal
  font-weight: 700
  font-size: 14px
  line-height: 135%
  color: #ffffff

.collection-type
  display: inline-block
  margin-right: 11px
  padding: 5px
  background: #7134f3
  border-radius: 50px
  color: #ffffff
  font-style: normal
  font-weight: 500
  font-size: 12px
  line-height: 135%

.info-block
  display: flex
  justify-content: space-between
  align-items: center
  flex-direction: row
  padding: 14px 20px 19px 10px

.under-title-info
  display: flex

.add-collection-btn
  background: #ffffff
  border: 2px solid #000000
  border-radius: 32px
  display: inline-block
  padding: 11px 20px 11px 20px
  cursor: pointer

  &:hover
    background: #404040
    color: #ffffff
</style>
