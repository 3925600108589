import axiosInstance2 from '@/plugins/serviceA';

const state = () => ({
    nftData: {},
    mainPageHeader: [],
    mainPageCollections: [],
    explorePageItems: [],
    mainPageRecentBids: [],
    mainPageRecentlyListed: [],
    mainPageNewlyMinted: [],
    mainPageRecentlySold: [],
    profilePageItems: [],
});

const mutations = {
    SET_NFT_DATA: (state, res) => {
        state.nftData = res.data;
    },
    SET_USERS_NFTS_DATA: (state, data) => {
        state[data.prop] = data.res;
    },
};

const actions = {
    /**
     * Get single nft
     *
     * @param commit
     * @param nftId
     *
     * @returns {Promise<void>}
     */
    async GET_NFT({commit}, nftId) {
        await axiosInstance2
            .get(`v1/nft/${nftId}`)
            .then(res => commit('SET_NFT_DATA', res))
            .catch(error => {
                // todo Проработать алерты в дизайне
                console.error(error);
            });
    },

    /**
     * Get nft or collection list
     *
     * @param commit
     * @param data
     *
     * @returns {Promise<void>}
     */
    async GET_USERS_NFTS({commit}, data) {
        await axiosInstance2
            .post('v1/user/nfts-collections', data.params)
            .then(res => {

                commit('SET_USERS_NFTS_DATA', {
                    res: res.data,
                    prop: data.prop,
                });
            })
            .catch(error => {
                // todo Проработать алерты в дизайне
                console.error(error);
            });
    },
};
export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
