import QUERIES from '@/services/graphQueries';

const state = () => ({
  itemDetails: {},
  userFollowsAuthor: false,
  userFollowsOwner: false,
});

const actions = {
  loadCurrentItem({ commit }, itemId) {
    return QUERIES.getCurrentItem(itemId)
      .then(response => {
        const item = response.data.getItemById;

        commit('SET_ITEM_DETAILS', item);
      })
      .catch(error =>
        // prettier-ignore
        console.info('%c ERROR in currentItem->loadItemDetails 🤬', 'color: #eee; background-color: #ff2020', error),
      );
  },
};

const mutations = {
  SET_ITEM_DETAILS: (state, details) => {
    state.itemDetails = details;
  },
  SET_USER_FOLLOWS_AUTHOR: (state, payload) => {
    state.userFollowsAuthor = payload;
  },
  SET_USER_FOLLOWS_OWNER: (state, payload) => {
    state.userFollowsOwner = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
