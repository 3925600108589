import axiosInstance2 from '@/plugins/serviceA';

const state = () => ({
  user: [],
});

const actions = {
  async GET_USER({ commit }, data) {
    await axiosInstance2
      .get(`v1/profile/${data.nick}`, {}, {})
      .then(res => commit('SET_USER', res.data))
      .catch(error => {
        // todo Проработать алерты в дизайне
        console.log(error);
      });
  },
};

const mutations = {
  SET_USER: (state, user) => {
    state.user = user;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
