import axiosInstance2 from '@/plugins/serviceA';
const state = () => ({
  filterFileType: [],
  filterCategories: [],
  filterArtists: [],
});

const mutations = {
  SET_FILTER_FILE_TYPE: (state, res) => {
    state.filterFileType = res.data;
  },
  SET_FILTER_CATEGORIES: (state, res) => {
    state.filterCategories = res.data;
  },
  SET_FILTER_ARTISTS: (state, res) => {
    state.filterArtists = res.data;
  },
};

const actions = {
  async GET_FILTER_FILE_TYPE({ commit }, type) {
    await axiosInstance2
      .get(
        `v1/directory?entity=${type}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${this.$jwt.getToken()}`,
          },
        },
      )
      .then(res => commit('SET_FILTER_FILE_TYPE', res))
      .catch(error => {
        console.log(error);
      });
  },

  async GET_FILTER_CATEGORIES({ commit }, type) {
    await axiosInstance2
      .get(
        `v1/directory?entity=${type}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${this.$jwt.getToken()}`,
          },
        },
      )
      .then(res => commit('SET_FILTER_CATEGORIES', res))
      .catch(error => {
        console.log(error);
      });
  },

  async GET_FILTER_ARTISTS({ commit }, type) {
    await axiosInstance2
      .get(
        `v1/directory?entity=${type}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${this.$jwt.getToken()}`,
          },
        },
      )
      .then(res => commit('SET_FILTER_ARTISTS', res))
      .catch(error => {
        console.log(error);
      });
  },
};
export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
