import resources from '@/services/endpoints';
import {
  AuthApiService,
  CrudApiService,
  ReadOnlyApiService,
  CrudApiService2,
} from '@/services/api.service';

export const createResources = () => ({
  [resources.AUTH]: new AuthApiService(),
  [resources.CAREERS]: new ReadOnlyApiService(resources.CAREERS),
  [resources.FAQ]: new ReadOnlyApiService(resources.FAQ),
  [resources.FILTERS]: new ReadOnlyApiService(resources.FILTERS),
  [resources.POSTS]: new ReadOnlyApiService(resources.POSTS),
  [resources.ACTIVITY]: new ReadOnlyApiService(resources.ACTIVITY),
  [resources.STATEMENT]: new ReadOnlyApiService(resources.STATEMENT),
  [resources.ITEMS]: new CrudApiService(resources.ITEMS),
  [resources.USERS]: new CrudApiService(resources.USERS),
  [resources.SETTINGS]: new CrudApiService(resources.SETTINGS),
  [resources.USER]: new CrudApiService2(resources.USER),
});
