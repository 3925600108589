<template>
  <div style="text-align: center; margin-top: 50px; margin-bottom: 500px">
    <h3 style="justify-self: center">Some magic will emerge here!</h3>
    <img
      style="
        display: block;
        margin-top: 50px;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        max-width: 530px;
      "
      :src="vzuh"
      alt="vzuh"
    />
  </div>
</template>

<script>
// eslint-disable-next-line import/no-unresolved
import vzuh from '/public/img/following/vzuh.png';

export default {
  name: 'UndefinedPage',

  setup() {
    return {
      vzuh,
    };
  },
};
</script>
