import axiosInstance2 from '@/plugins/serviceA';

const actions = {
    // eslint-disable-next-line max-lines-per-function
  async POST_POSITION({ dispatch }, [position, nft_id, message]) {
    await axiosInstance2.post('/v1/nft/purchase',
        {
          nft_id: nft_id,
          sale_id: 1,
          step_id: 1, // type
          location: {
            lng: position.coords.longitude,
            lat: position.coords.latitude,
          },
        },
        {
          headers: { Authorization: `Bearer ${this.$jwt.getToken()}` },
        },
      )
      .then(res => {
        if (res.status === 204) {
          dispatch('POST_BIDS', [position, nft_id, message]);
        } else {
          console.log(res.text);
        }
      })
      .catch(() => console.log('Your location is further than 5 meters'));
  },

  async POST_BIDS(_, [position, nft_id, message]) {
    await axiosInstance2
      .post(
        '/v1/nft/purchase',
        {
          nft_id: nft_id,
          sale_id: 1,
          step_id: 2,
          location: {
            lng: position.coords.longitude,
            lat: position.coords.latitude,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${this.$jwt.getToken()}`,
          },
        },
      )
      .then(() => console.log(message))
      .catch(error => console.log(error));
  },
};

export default {
  namespaced: true,
  actions,
};
