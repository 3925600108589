<template>
  <div class="loader">
    <svg viewBox="0 0 90 90"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path
        d="M90 45C90 69.8528 69.8528 90 45 90C20.1472 90 0 69.8528 0 45C0 20.1472 20.1472 0 45 0C69.8528 0 90 20.1472 90 45ZM13.9358 45C13.9358 62.1563 27.8437 76.0642 45 76.0642C62.1563 76.0642 76.0642 62.1563 76.0642 45C76.0642 27.8437 62.1563 13.9358 45 13.9358C27.8437 13.9358 13.9358 27.8437 13.9358 45Z"
        fill="#E4E4E4"
      />
      <path
        d="M90 45C90 38.0182 88.3755 31.1322 85.2548 24.8866C82.1342 18.6411 77.6032 13.2073 72.0201 9.01509C66.437 4.8229 59.9551 1.98731 53.087 0.732617C46.2188 -0.522079 39.153 -0.161462 32.4483 1.78595L36.3354 15.1687C40.9637 13.8244 45.8414 13.5754 50.5825 14.4416C55.3237 15.3077 59.7983 17.2652 63.6524 20.1591C67.5065 23.053 70.6343 26.804 72.7885 31.1154C74.9427 35.4269 76.0642 40.1804 76.0642 45H90Z"
        fill="#2787F5"
      />
    </svg>
  </div>
</template>

<style lang="sass">
@import "@/assets/sass/components/_loader.sass"
</style>
