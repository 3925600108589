import axiosInstance2 from '@/plugins/serviceA';
const state = () => ({
    items: {},
});

const mutations = {
    SET_ITEMS: (state, data) => {
        state.items[data.name] = data.res.data;
        console.log(state.items);
    },
};

const actions = {
    async GET_ITEMS({ commit }, data) {
        await axiosInstance2
            .get(`v1/directory?entity=${data.directory}`)
            .then(res => commit('SET_ITEMS', {
                res: res,
                name: data.name,
            }))
            .catch(error => {
                console.log(error);
            });
    },
};
export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
