<template>
  <div class="main-view">
    <a :href="`/auction/${data?.id}`">
      <div
        v-if="componentState === 'image'"
        :class="[
          'image-background',
          bigSizeCard ? 'big-size' : '',
          carouselCard ? 'carousel-card' : '',
        ]"
      >
        <img
          :src="`${pinataAdress}${data?.file_path?.replace('ipfs://', '')}`"
          :class="['image', bigSizeCard ? 'big-size' : '']"
          crossorigin="anonymous"
        />
      </div>
      <div v-if="componentState === 'map'">
        <MapBlock
          ref="map"
          :center-coordinates="[data?.location?.lng, data?.location?.lat]"
          :big-size-card="bigSizeCard"
          :carousel-card="carouselCard"
        />
      </div>
    </a>
    <div class="info-panel">
      <div class="price-block">
        <div class="nft-name">{{ data?.name }}</div>
        <div v-if="data?.price"
             class="price">{{ data.price }} ETH</div>
      </div>
      <div class="place-author-block">
        <div class="location-bookmark"
             @click="changeComponentState">
          <div class="markship"></div>
          {{ data?.location?.name }}
        </div>
        <NftAuthor
          :data="{
            avatar: data?.creator?.download_avatar_path,
            username: data?.creator?.username,
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getLocation } from '@/helpers/getLocation';
import 'mapbox-gl/src/css/mapbox-gl.css';
import MapBlock from '@/components/_UI/map/MapBlock.vue';
import NftAuthor from '@/components/_UI/viewCardComponent/NftAuthor.vue';

export default {
  name: 'ViewCardComponent',

  components: {
    MapBlock,
    NftAuthor,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    bigSizeCard: {
      type: Boolean,
      default: false,
    },
    carouselCard: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mapCenter: [-77.035915, 38.889814],
      componentState: 'image',
      pinataAdress: process.env.VUE_APP_PINATA_ADRESS,
      pinataToken: process.env.VUE_APP_PINATA_TOKEN,
    };
  },
  async mounted() {
    const location = await getLocation();
    this.mapCenter = [location.coords.longitude, location.coords.latitude];
    this.$refs?.map?.setValue(this.mapCenter);
  },
  methods: {
    changeComponentState() {
      if (this.componentState === 'image') {
        this.componentState = 'map';
      } else {
        this.componentState = 'image';
      }
    },
  },
};
</script>

<style scoped>
.image-background {
  min-height: 70%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centering y-axis */
  align-items: center; /* Centering x-axis */
  background-color: #242424;
  z-index: 2;
  position: relative;
  border-radius: 15px;
  overflow: hidden;
}

.image-background.big-size {
  max-width: 400px;
  max-height: 400px;
  min-height: 400px;
}
.image-background.carousel-card {
  max-width: 400px;
  max-height: 400px;
  min-height: 400px;
}
@media only screen and (max-width: 1700px) {
  .image-background.carousel-card {
    max-height: 300px;
    min-height: 300px;
  }
}
@media only screen and (max-width: 1400px) {
  .image-background.carousel-card {
    max-height: 350px;
    min-height: 350px;
  }
}
@media only screen and (max-width: 1320px) {
  .image-background.big-size {
    max-height: 300px;
    min-height: 300px;
  }
}
@media only screen and (max-width: 1200px) {
  .image-background.carousel-card {
    max-height: 300px;
    min-height: 300px;
  }
}
@media only screen and (max-width: 1100px) {
  .image-background.carousel-card {
    max-height: 400px;
    min-height: 400px;
  }
}
@media only screen and (max-width: 1070px) {
  .image-background.big-size {
    max-height: 400px;
    min-height: 400px;
  }
}
@media only screen and (max-width: 920px) {
  .image-background.big-size {
    max-height: 300px;
    min-height: 300px;
  }
}
@media only screen and (max-width: 900px) {
  .image-background.carousel-card {
    max-height: 300px;
    min-height: 300px;
  }
}
@media only screen and (max-width: 700px) {
  .image-background.carousel-card {
    max-height: 400px;
    min-height: 400px;
  }
  .image-background.big-size {
    max-height: 400px;
    min-height: 400px;
  }
}
@media only screen and (max-width: 530px) {
  .image-background.big-size {
    max-height: 330px;
    min-height: 330px;
  }
}
@media only screen and (max-width: 520px) {
  .image-background.carousel-card {
    max-height: 330px;
    min-height: 330px;
  }
}

.main-view {
  height: 100%;
  border-radius: 15px;
}

/* при открытии модалки могут случится наложения z-index */
.image {
  position: absolute;
  height: auto;
  z-index: 9999;
  width: 100%;
}

.info-panel {
  position: relative;
  background: #f5f5f5;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  width: 100%;
  padding: 14px 15px 19px 7px;
  z-index: 1;
}
.info-panel::before {
  content: '';
  position: absolute;
  top: -14px;
  width: 100%;
  left: 0;
  right: 0;
  height: 15px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background-color: #f5f5f5;
}
.price-block {
  display: flex;
  justify-content: space-between;
  margin-bottom: 9px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
}

.place-author-block {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.markship {
  background-image: url(~@/assets/img/icons/markship.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 7.5px;
  height: 9px;
  margin-right: 5px;
}
.location-bookmark {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px 5px 6px;
  height: 26px;
  background: #000000;
  border-radius: 50px;
  color: #ffffff;
  font-family: 'Inter', serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 135%;
  cursor: pointer;
}
</style>
