<template>
  <div
    :id="`simple-map${containerSalt}`"
    :class="['map', bigSizeCard ? 'big-map' : '', carouselCard ? 'carousel-map' : '']"
  ></div>
  <div ref="mapMarker"
       class="mapMarker"></div>
</template>

<script>
import mapboxgl from 'mapbox-gl';

export default {
  name: 'MapBlock',

  props: {
    centerCoordinates: {
      type: Array,
      required: false,
      default: () => [30.326575, 59.940849],
    },
    bigSizeCard: {
      type: Boolean,
      default: false,
    },
    carouselCard: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['locationInputChange'],
  data() {
    return {
      location: this.centerCoordinates,
      map: null,
      containerSalt: Math.random(),
    };
  },
  mounted() {
    this.initMapbox();
  },
  methods: {
    setValue(coordinates) {
      this.location = coordinates;
      this.marker.setLngLat(this.location);
      this.map.setCenter(this.location);
    },
    initMapbox() {
      mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_TOKEN;
      this.marker = this.$refs.mapMarker;
      this.map = new mapboxgl.Map({
        container: `simple-map${this.containerSalt}`,
        style: 'mapbox://styles/mapbox/outdoors-v11',
        center: this.location,
        zoom: 10,
        scrollZoom: true,
      });

      this.marker = new mapboxgl.Marker(this.marker).setLngLat(this.location).addTo(this.map);
    },
  },
};
</script>

<style scoped>
.mapMarker {
  background-image: url(~@/assets/img/icons/mapMarker.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 45px;
  height: 45px;
}
.map {
  height: 240px;
  width: 100%;
  border-radius: 15px;
  z-index: 2;
}
.big-map {
  height: 400px;
}
.carousel-map {
  height: 400px;
}
@media only screen and (max-width: 1700px) {
  .carousel-map {
    height: 300px;
  }
}
@media only screen and (max-width: 1400px) {
  .carousel-map {
    height: 350px;
  }
}

@media only screen and (max-width: 1320px) {
  .big-map {
    height: 300px;
  }
}

@media only screen and (max-width: 1200px) {
  .carousel-map {
    height: 300px;
  }
}
@media only screen and (max-width: 1100px) {
  .carousel-map {
    height: 400px;
  }
}
@media only screen and (max-width: 1070px) {
  .big-map {
    height: 400px;
  }
}
@media only screen and (max-width: 920px) {
  .big-map {
    height: 300px;
  }
}
@media only screen and (max-width: 900px) {
  .carousel-map {
    height: 300px;
  }
}
@media only screen and (max-width: 700px) {
  .carousel-map {
    height: 400px;
  }
  .big-map {
    height: 400px;
  }
}
@media only screen and (max-width: 530px) {
  .big-map {
    height: 330px;
  }
}
@media only screen and (max-width: 520px) {
  .carousel-map {
    height: 330px;
  }
}
</style>
