const state = () => ({
  posts: [],
});

const actions = {
  loadPosts({ commit }) {
    return this.$api.posts.query().then(posts => commit('SET_POSTS', posts));
  },
};

const mutations = {
  SET_POSTS: (state, posts) => {
    state.posts = posts;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
