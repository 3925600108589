import categoryBg1 from '@/assets/img/main-page/categories/category-bg-1.png';
import categoryBg2 from '@/assets/img/main-page/categories/category-bg-2.png';
import categoryBg3 from '@/assets/img/main-page/categories/category-bg-3.png';
import categoryBg4 from '@/assets/img/main-page/categories/category-bg-4.png';
import categoryBg5 from '@/assets/img/main-page/categories/category-bg-5.png';
import categoryBg6 from '@/assets/img/main-page/categories/category-bg-6.png';
import categoryBg7 from '@/assets/img/main-page/categories/category-bg-7.png';
import categoryBg8 from '@/assets/img/main-page/categories/category-bg-8.png';

export const allCategories = [
  {
    id: 1,
    name: 'art',
    img: categoryBg1,
  },
  {
    id: 2,
    name: 'animation',
    img: categoryBg2,
  },
  {
    id: 3,
    name: 'collectibles',
    img: categoryBg3,
  },
  {
    id: 5,
    name: 'generative',
    img: categoryBg4,
  },
  {
    id: 6,
    name: 'illustration',
    img: categoryBg5,
  },
  {
    id: 7,
    name: 'photography',
    img: categoryBg6,
  },
  {
    id: 8,
    name: 'Sports',
    img: categoryBg7,
  },
  {
    id: 9,
    name: '3d\u00A0art',
    img: categoryBg8,
  },
];
