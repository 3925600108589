export const checkInSessionStorage = itemKey => sessionStorage.getItem(itemKey);

export const getFromSessionStorage = itemKey => JSON.parse(checkInSessionStorage(itemKey));

export const saveToSessionStorage = (itemKey, data) => {
  sessionStorage.setItem(itemKey, JSON.stringify(data));
};

export const checkInLocalStorage = itemKey => localStorage.getItem(itemKey);

export const saveToLocaleStorage = (itemKey, data) => {
  localStorage.setItem(itemKey, JSON.stringify(data));
};

export const delFromLocaleStorage = itemKey => {
  localStorage.removeItem(itemKey);
};
