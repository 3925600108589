<template>
  <ul class="pagination">
    <li
      v-for="page in pageCount"
      :key="page"
      :class="['pagination__item', { active: currentPage === page }]"
      @click="changePage(page)"
    >
      <button class="pagination__btn">
        {{ page > 10 ? page : `0${page}` }}
      </button>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'AppPagination',

  props: {
    pageCount: {
      type: Number,
      default: 0,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    changePage: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style lang="sass">
@import "@/assets/sass/components/_pagination.sass"
</style>
