import JWTService from '@/services/jwt.service';
import { createResources } from '@/helpers/createResources';

export default {
  install: app => {
    app.mixin({
      computed: {
        $jwt: () => JWTService,
        $api: () => createResources(),
      },
    });
  },
};
