import MainPage from '@/views/MainPage.vue';
import NotFoundPage from '@/views/NotFoundPage.vue';
import store from '@/store';
import { auth, checkAuth } from './middleware/auth.middleware';
import FollowingPage from '@/components/user-page/FollowingPage.vue';
import CreatedSection from '@/components/user-page/CreatedSection.vue';
import UndefinedPage from '@/components/user-page/UndefinedPage.vue';

const storeInit = store.dispatch('init');

export const routes = [
  {
    path: '/',
    name: 'MainPage',
    component: MainPage,
    meta: { marginReset: true, layout: 'main-page' },
    beforeEnter: (to, from, next) => checkAuth({ next }),
  },
  {
    path: '/activity',
    name: 'ActivityPage',
    component: () => import(/* webpackChunkName: "activity" */ '@/views/ActivityPage.vue'),
    beforeEnter: (to, from, next) => auth({ next }),
  },
  {
    path: '/explore',
    name: 'ExplorePage',
    component: () => import(/* webpackChunkName: "explore" */ '@/views/ExplorePage.vue'),
    meta: {},
    beforeEnter: (to, from, next) => checkAuth({ next }),
  },
  {
    path: '/statement',
    name: 'StatementPage',
    component: () => import(/* webpackChunkName: "statement" */ '@/views/StatementPage.vue'),
    beforeEnter: (to, from, next) => auth({ next }),
  },
  {
    path: '/sellers',
    name: 'SellersPage',
    component: UndefinedPage,
    beforeEnter: (to, from, next) => checkAuth({ next }),
  },
  {
    path: '/creators',
    name: 'CreatorsPage',
    component: () => import(/* webpackChunkName: "sellers" */ '@/views/CreatorsPage.vue'),
    beforeEnter: (to, from, next) => checkAuth({ next }),
  },
  {
    path: '/profile',
    name: 'ProfilePage',
    component: () => import(/* webpackChunkName: "profile" */ '@/views/ProfilePage.vue'),
    beforeEnter: (to, from, next) => auth({ next }),
  },
  {
    path: '/profile/preview',
    name: 'ProfilePreviewPage',
    component: () =>
      import(/* webpackChunkName: "profile-preview" */ '@/views/ProfilePreviewPage.vue'),
    beforeEnter: (to, from, next) => auth({ next }),
  },
  {
    path: '/settings',
    component: () => import('@/views/SettingsPage.vue'),
    beforeEnter: (to, from, next) => auth({ next }),
    children: [
      {
        path: '',
        redirect: { name: 'ProfileSettings' },
      },
      {
        path: 'profile',
        name: 'ProfileSettings',
        component: () =>
          import(/* webpackChunkName: "settings" */ '@/views/SettingsProfilePage.vue'),
      },
      {
        path: 'notifications',
        name: 'NotificationSettings',
        component: () =>
          import(/* webpackChunkName: "settings" */ '@/views/SettingsNotificationsPage.vue'),
      },
      {
        path: 'offers',
        name: 'OffersSettings',
        component: () =>
          import(/* webpackChunkName: "settings" */ '@/views/SettingsOffersPage.vue'),
      },
      {
        path: 'support',
        name: 'AccountSupportSettings',
        component: () =>
          import(/* webpackChunkName: "settings" */ '@/views/SettingsAccountSupportPage.vue'),
      },
    ],
  },
  {
    path: '/blog',
    name: 'BlogPage',
    component: UndefinedPage,
    beforeEnter: (to, from, next) => checkAuth({ next }),
  },
  {
    path: '/community-guidelines',
    name: 'CommunityGuidelinesPage',
    component: UndefinedPage,
    beforeEnter: (to, from, next) => checkAuth({ next }),
  },
  {
    path: '/careers',
    name: 'CareersPage',
    component: UndefinedPage,
    beforeEnter: (to, from, next) => checkAuth({ next }),
  },
  {
    path: '/careers/:roleId',
    component: () => import(/* webpackChunkName: "role" */ '@/views/RolePage.vue'),
    children: [
      {
        path: '',
        name: 'RolePage',
        component: () =>
          import(/* webpackChunkName: "role" */ '@/components/role-page/RoleDescription.vue'),
      },
      {
        path: 'submit-application',
        name: 'RoleSubmitApplication',
        meta: { applyBtn: false, marginReset: true },
        component: () =>
          import(/* webpackChunkName: "role" */ '@/components/role-page/RoleSubmitApplication.vue'),
      },
    ],
    beforeEnter: (to, from, next) => checkAuth({ next }),
  },
  {
    path: '/items/:id',
    name: 'ItemPage',
    component: () => import(/* webpackChunkName: "item" */ '@/views/ItemPage.vue'),
    beforeEnter: (to, from, next) => {
      storeInit.then(() => next());
    },
  },
  {
    path: '/upload-item',
    name: 'UploadItemPage',
    component: () => import(/* webpackChunkName: "upload-item" */ '@/views/NewUploadItemPage.vue'),
    props: true,
    beforeEnter: (to, from, next) => auth({ next }),
  },
  {
    path: '/help',
    name: 'HelpPage',
    meta: { headerHelp: true },
    component: UndefinedPage,
    beforeEnter: (to, from, next) => checkAuth({ next }),
  },
  {
    path: '/help/:id',
    name: 'HelpSectionPage',
    meta: { headerHelp: true },
    component: () => import(/* webpackChunkName: "404" */ '@/views/HelpSectionPage.vue'),
    beforeEnter: (to, from, next) => checkAuth({ next }),
  },
  {
    path: '/about',
    name: 'AboutPage',
    meta: { marginReset: true, layout: 'about' },
    component: UndefinedPage,
    beforeEnter: (to, from, next) => checkAuth({ next }),
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: () => import(/* webpackChunkName: "login" */ '@/views/LoginPage.vue'),
    beforeEnter: (to, from, next) => checkAuth({ next }),
  },
  {
    path: '/map',
    name: 'MapPage',
    meta: { layout: 'map' },
    component: () => import(/* webpackChunkName: "map" */ '@/views/MapPage.vue'),
    beforeEnter: (to, from, next) => checkAuth({ next }),
  },
  {
    path: '/preview',
    name: 'ItemPreviewPage',
    component: () => import(/* webpackChunkName: "preview" */ '@/views/ItemPreviewPage.vue'),
    beforeEnter: (to, from, next) => checkAuth({ next }),
  },
  {
    path: '/add-collection',
    name: 'AddCollection',
    component: () => import(/* webpackChunkName: "preview" */ '@/views/AddCollection.vue'),
    beforeEnter: (to, from, next) => checkAuth({ next }),
  },
  {
    path: '/:profileNick/:type',
    name: 'FollowingPage',
    component: () => import(/* webpackChunkName: "userpage" */ '@/views/UserPage.vue'),
    children: [
      { path: '', component: FollowingPage },
      { path: 'created', component: CreatedSection },
      { path: 'collected', component: UndefinedPage },
      { path: 'liked', component: UndefinedPage },
      { path: 'following', component: FollowingPage },
    ],
    beforeEnter: (to, from, next) => checkAuth({ next }),
  },
  {
    path: '/create-auction',
    name: 'CreateAuction',
    component: () => import(/* webpackChunkName: "userpage" */ '@/views/CreateAuctionPage.vue'),
    beforeEnter: (to, from, next) => checkAuth({ next }),
  },
  {
    path: '/auction/:id',
    name: 'Auction',
    component: () => import(/* webpackChunkName: "userpage" */ '@/views/AuctionPage.vue'),
    beforeEnter: (to, from, next) => checkAuth({ next }),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFoundPage',
    component: NotFoundPage,
    beforeEnter: (to, from, next) => checkAuth({ next }),
  },
  {
    path: '/base-component',
    name: 'base-component',
    component: () => import('@/components/base-page/BaseComponent.vue'),
    beforeEnter: (to, from, next) => checkAuth({ next }),
  },
];
